import { useEffect, useMemo } from 'react';
import { DailyParticipantUpdateOptions } from '@daily-co/daily-js';
import { useDaily, useLocalSessionId } from '@daily-co/daily-react';

import { useAvControls } from '~/store/hooks';

import { useCallData, useAvSpaceParticipants } from '../hooks';
import { ParticipantAudio } from '../components/ParticipantAudio';

function AudioTracks({ subscribeToParticipants }: { subscribeToParticipants: string[] }) {
  const { callObjectRef, callParticipants, localSessionId } = useCallData();

  useEffect(() => {
    if (callObjectRef.current) {
      const updates: Record<string, DailyParticipantUpdateOptions> = {};
      const participants = callObjectRef.current.participants();
      Object.keys(participants).forEach((participantSessionId) => {
        if (localSessionId !== participantSessionId && participantSessionId !== 'local') {
          if (subscribeToParticipants.includes(participantSessionId)) {
            updates[participantSessionId] = {
              setSubscribedTracks: {
                audio: true,
                video: true, // for now - work out how to segregate later
                screenAudio: false,
                screenVideo: false,
              },
            };
          } else {
            updates[participantSessionId] = { setSubscribedTracks: false };
          }
        }
      });
      callObjectRef.current.updateParticipants(updates);
    }
  }, [callObjectRef, localSessionId, subscribeToParticipants]);

  const renderAudio = useMemo(() => {
    return callParticipants.map((p) => p.session_id).filter((id) => id !== localSessionId);
  }, [localSessionId, callParticipants]);

  console.log('audio', renderAudio);

  return (
    <>
      {renderAudio.map((p) => {
        return <ParticipantAudio key={p} id={p} />;
      })}
    </>
  );
}

export const AvListener = ({ appState }: { appState: number }) => {
  const { participants: activeAvSpaceParticipants } = useAvSpaceParticipants();
  const { audioEnabled, videoEnabled } = useAvControls();

  const localSessionId = useLocalSessionId();
  const callObject = useDaily();

  const subscribeToParticipants = useMemo(() => {
    return activeAvSpaceParticipants.map((u) => u.sessionId).filter((s) => !!s) as string[];
  }, [activeAvSpaceParticipants]);

  useEffect(() => {
    if (callObject) {
      callObject.updateParticipant(localSessionId, {
        setAudio: audioEnabled,
        setVideo: videoEnabled,
      });
    }
  }, [audioEnabled, videoEnabled, callObject, localSessionId]);

  if (appState > 0) {
    return <AudioTracks subscribeToParticipants={subscribeToParticipants} />;
  }

  return null;
};
