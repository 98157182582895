import { useState, useCallback, useMemo, useRef } from 'react';

import { WsMethod, ApiResponse } from '../api.types';
import { reportError } from '~/module/logging';
import { useWsApi } from './useWsApi';
import { useAuth } from '~/store/hooks';
import { getFreshIdToken } from '~/module/firebase';

export class ApiError extends Error {
  code: string;

  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
}

export interface UseSyncRequestReturn<RequestDataType, ResponseDataType> {
  isLoading: boolean;
  error: Maybe<ApiError>;
  called: boolean;
  send: (data?: RequestDataType) => Promise<ApiResponse<ResponseDataType> | null>;
}

export interface UseSyncRequestProps<RequestDataType> {
  method: WsMethod;
  data?: RequestDataType;
}

export function useWsSyncRequest<RequestDataType, ResponseDataType>({
  method,
  data,
}: UseSyncRequestProps<RequestDataType>): UseSyncRequestReturn<RequestDataType, ResponseDataType> {
  const ws = useWsApi();

  const { userIdToken } = useAuth();
  const userIdTokenRef = useRef(userIdToken);
  userIdTokenRef.current = userIdToken;

  const [called, setCalled] = useState<boolean>(false);
  const [error, setError] = useState<Maybe<ApiError>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const message = useMemo(() => {
    return {
      method,
      data,
    };
  }, [method, data]);

  const send = useCallback(
    async (
      data?: RequestDataType,
      opts?: { retryIfAuthFails?: boolean },
    ): Promise<ApiResponse<ResponseDataType> | null> => {
      setCalled(true);
      setIsLoading(true);
      setError(null);
      try {
        if (!ws) {
          setError(new ApiError('CLIENT', 'API not ready'));
          return null;
        }
        const messageToSend = data
          ? {
              ...message,
              data: {
                ...message.data,
                ...data,
              },
            }
          : message;
        const response = await ws.makeSynchronousRequest<RequestDataType, ResponseDataType>(
          messageToSend,
        );
        if (response && response.status === 'OK') {
          setIsLoading(false);
          return response;
        } else if (response && response.status === 'ERROR') {
          if (response.exception) {
            if (response.exception.code === 5002 || response.exception.code === 5003) {
              const idToken = await getFreshIdToken();
              if (idToken || userIdTokenRef.current) {
                await ws.setAuthToken(idToken ?? userIdTokenRef.current);
                if (opts?.retryIfAuthFails !== false) {
                  return send(data, { retryIfAuthFails: false });
                }
              }
            }
            throw new ApiError('SERVER_ERROR', response.exception.message);
          } else if (response.message) {
            // @ts-ignore
            if (response.data?.invalid_reason) {
              // @ts-ignore
              throw new ApiError(response.data.invalid_reason, response.message);
            } else {
              throw new ApiError('UNKNOWN', response.message);
            }
          } else {
            throw new ApiError('UNKNOWN', 'Unexpected error');
          }
        }
      } catch (err) {
        if (err instanceof ApiError) {
          reportError('Error sending sync request', err);
          setError(err);
        }
      }
      setIsLoading(false);
      return null;
    },
    [ws, message],
  );

  return {
    isLoading,
    called,
    error,
    send,
  };
}
