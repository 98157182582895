import { FC, useMemo } from 'react';
import _find from 'lodash/find';

import { Box, Button, Text } from '~/components/common';
import { useAvailableSpaces, useUser } from '~/store/hooks';
import { Space } from '~/store/slices';

export const RejoinActiveRace: FC<{ space: Space; onNavToSpace: (spaceId: string) => void }> = ({
  space,
  onNavToSpace,
}) => {
  return (
    <Box align="center" pad="small" gap="small" direction="row" justify="between" width="100%">
      <Box gap="xsmall">
        <Text size="large">Race in progress</Text>
        <Text size="xlarge" uppercase>
          {space.id}
        </Text>
      </Box>
      <Button size="large" primary label="Re-join race" onClick={() => onNavToSpace(space.id)} />
    </Box>
  );
};

const useRejoinActiveRace = () => {
  const { availableSpaces } = useAvailableSpaces();
  const { currentActiveRace } = useUser();
  const activeRace = useMemo(() => {
    if (currentActiveRace) {
      const space = _find(availableSpaces, ['id', currentActiveRace]);
      if (space && space.details.state !== 'finished') {
        return space;
      }
    }
    return null;
  }, [availableSpaces, currentActiveRace]);

  return {
    space: activeRace,
  };
};

export const RejoinActiveRaceConnected = ({
  onNavToSpace,
}: {
  onNavToSpace: (spaceId: string) => void;
}) => {
  const { space } = useRejoinActiveRace();
  if (!space) return null;
  return <RejoinActiveRace space={space} onNavToSpace={onNavToSpace} />;
};
