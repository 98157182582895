import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';

import {
  TournamentRoundInfo,
  spacesSelectors,
  tournamentsActions,
  tournamentsSelectors,
} from '~/store/slices';
import { useAuth, useUser } from '~/store/hooks';
import { useWsApi, useWsAuthedStatus } from '~/module/api';
import { useFirestoreTournamentListener } from '~/module/firebase/firestore/useFirestoreTournaments';

import { useTournamentActions } from './useTournamentActions';
import { getTournamentRoundNumber } from '../tournaments.utils';

export const useTournamentState = (tournamentId: string) => {
  useFirestoreTournamentListener(tournamentId);
  const dispatch = useDispatch();

  const { authStateKnown, authenticated } = useAuth();
  const wsAuthed = useWsAuthedStatus(useWsApi());
  const { userId } = useUser();
  const {
    isFetchingTournamentEntries,
    getMyTournamentEntries,
    isJoining,
    onJoinTournament: joinTournament,
    isLeaving,
    onLeaveTournament: leaveTournament,
    isConfirmingEntry,
    onConfirmEntry: confirmEntry,
  } = useTournamentActions(tournamentId);

  const ready = authStateKnown && authenticated && wsAuthed;

  const clockOffset = useSelector(spacesSelectors.getClockOffset);
  const tournaments = useSelector(tournamentsSelectors.getTournamentsList);
  const tournamentDetail = useSelector(
    tournamentsSelectors.getTournamentDetailSelector(tournamentId),
  );
  const tournamentEntries = useSelector(tournamentsSelectors.getTournamentEntries);

  const tournament = useMemo(() => {
    const t = _find(tournaments, ['id', tournamentId]);
    if (t) {
      return {
        ...t,
        details: {
          ...t.details,
          startTimeWithOffset: t.details.startTime - clockOffset,
          confirmationWindowOpens: t.details.confirmationWindowOpens - clockOffset,
          registrationCloseTime: t.details.registrationCloseTime - clockOffset,
        },
      };
    }
    return null;
  }, [tournamentId, tournaments, clockOffset]);

  const tournamentEntry = useMemo(() => {
    return _find(tournamentEntries, ['id', tournamentId]) || null;
  }, [tournamentEntries, tournamentId]);

  const hasConfirmed = useMemo(() => {
    return tournamentEntry?.riderStatus === 'confirmed';
  }, [tournamentEntry]);

  const estimatedNumberOfRounds = useMemo(() => {
    if (tournament) {
      return Math.ceil(Math.log(tournament.signups) / Math.log(4));
    }
    return 0;
  }, [tournament]);

  const riderRanks = useMemo(() => {
    return tournamentDetail?.riderRanks ?? [];
  }, [tournamentDetail]);

  const rounds = useMemo(() => {
    return (
      tournamentDetail?.rounds?.map(
        (r): TournamentRoundInfo => ({
          ...r,
          earliestRaceStart: r.earliestRaceStart - clockOffset,
        }),
      ) ?? []
    );
  }, [tournamentDetail, clockOffset]);

  const currentRound = useMemo(() => {
    if (tournament && tournamentDetail) {
      const roundNumber = getTournamentRoundNumber({
        tournamentState: tournament.state,
        numRounds: tournamentDetail.rounds.length,
      });
      return rounds.find((r) => r.roundNumber === roundNumber) ?? null;
    }
    return null;
  }, [rounds, tournament, tournamentDetail]);

  const previousRounds = useMemo(() => {
    if (currentRound) {
      return _orderBy(
        rounds.filter((round) => {
          return round.roundNumber < currentRound.roundNumber;
        }),
        ['roundNumber'],
        ['desc'],
      );
    }
    return [];
  }, [currentRound, rounds]);

  const currentRaceId = useMemo(() => {
    if (currentRound && tournamentEntry) {
      if (currentRound.state === 'pending' || currentRound.state === 'racing') {
        const spaceAllocation = currentRound.spaceAllocation?.[userId];
        if (spaceAllocation) {
          return spaceAllocation;
        }
      }
    }
    return null;
  }, [currentRound, tournamentEntry, userId]);

  const isStillInTournament = useMemo(() => {
    if (!currentRound && !previousRounds.length) {
      return !!tournamentEntry;
    }
    if (!currentRound?.roundQualifiers?.length) {
      return previousRounds[previousRounds.length - 1]?.roundQualifiers?.includes(userId) ?? false;
    }
    return currentRound.roundQualifiers.includes(userId);
  }, [currentRound, previousRounds, tournamentEntry, userId]);

  const onFetchTournamentEntries = useCallback(async () => {
    const response = await getMyTournamentEntries();
    if (response && response.data) {
      dispatch(
        tournamentsActions.onTournamentEntriesUpdated({
          tournamentEntries: response.data.tournaments.map((t) => ({
            id: t,
            riderStatus: 'pending-confirmation',
          })),
        }),
      );
    }
  }, [dispatch, getMyTournamentEntries]);

  const onJoinTournament = useCallback(async () => {
    await joinTournament();
    await onFetchTournamentEntries();
  }, [joinTournament, onFetchTournamentEntries]);

  const onLeaveTournament = useCallback(async () => {
    await leaveTournament();
    await onFetchTournamentEntries();
  }, [leaveTournament, onFetchTournamentEntries]);

  const onConfirmEntry = useCallback(async () => {
    await confirmEntry();
    await onFetchTournamentEntries();
  }, [confirmEntry, onFetchTournamentEntries]);

  useEffect(() => {
    if (ready) {
      onFetchTournamentEntries();
    }
  }, [onFetchTournamentEntries, ready]);

  return {
    isFetchingTournamentEntries,
    tournament,
    hasJoined: !!tournamentEntry,
    hasConfirmed,
    estimatedNumberOfRounds,
    isJoining,
    onJoinTournament,
    isLeaving,
    onLeaveTournament,
    isConfirmingEntry,
    onConfirmEntry,
    // detail
    riderRanks,
    rounds,
    currentRound,
    previousRounds,
    isStillInTournament,
    currentRaceId,
  };
};
