import { useNavigate } from 'react-router-dom';

import { SceneComponent } from '~/navigation/Navigation.types';
import { LandingPage } from '~/features/Pages';

import { absoluteUrls } from '../Navigation.paths';
import { Scene } from '../layout/Scene';

export const LandingPageScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();
  return (
    <Scene title="Breakaway from boring" pad="none">
      <LandingPage onNavToSignUp={() => navigate(absoluteUrls.signUp)} />
    </Scene>
  );
};
