import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { ENVIRONMENT, IS_DEV_MODE, SENTRY_DSN } from '../config';

export const initLogging = () => {
  if (!IS_DEV_MODE) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: ENVIRONMENT,
      // release: SENTRY_RELEASE,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

export function setLogUser(userId: string | null) {
  Sentry.setUser(userId ? { id: userId } : null);
}

export function reportWarning(message: string) {
  if (!IS_DEV_MODE) {
    Sentry.captureMessage(message, 'warning');
  } else {
    console.warn(message);
  }
}

export function reportError(message: string, error?: Error | unknown) {
  let exception;
  if (error) {
    if (error instanceof Error) {
      exception = new Error(message);
      exception.cause = error;
    } else {
      exception = new Error(`${message} - ${JSON.stringify(error)}`);
    }
  } else {
    exception = new Error(message);
  }
  if (!IS_DEV_MODE) {
    Sentry.captureException(exception);
  } else {
    console.error(exception);
  }
}
