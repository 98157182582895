import { FC, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box } from '~/components/common';
import { Logo } from '~/components/Logo';
import { ConnectedDevicesSummary } from '~/features/Devices';
import { HeaderProfile } from '~/features/User';
import { absoluteUrls } from '~/navigation/Navigation.paths';
import { useAuth, useUser } from '~/store/hooks';

export const Header: FC<{ onOpenDevicesModal: () => void }> = ({ onOpenDevicesModal }) => {
  const { authenticated, email } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();

  const onNavToProfile = useCallback(() => {
    navigate(absoluteUrls.userProfile);
  }, [navigate]);

  return (
    <>
      {!!user && authenticated && (
        <Box pad={{ right: 'medium' }} direction="row" justify="between">
          <Box justify="center" pad="small">
            <Link to={absoluteUrls.home} className="no-style">
              <Logo width={200} />
            </Link>
          </Box>

          <Box direction="row" gap="large" align="center">
            <Box onClick={onOpenDevicesModal} focusIndicator={false}>
              <ConnectedDevicesSummary />
            </Box>

            <Box pad={{ vertical: 'small' }}>
              <HeaderProfile
                displayName={user.displayName || ''}
                userName={user.userName || ''}
                email={email || ''}
                onClick={onNavToProfile}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
