import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { spacesSelectors } from '../slices';
import { useRaceSpaceActions } from '~/features/Spaces/components/useRaceSpaceActions';

export function useActiveSpace() {
  const activeSpace = useSelector(spacesSelectors.getActiveSpace);
  const spaceParticipants = useSelector(spacesSelectors.getSpaceParticipants);

  const { participants, observers } = useMemo(() => {
    if (activeSpace && spaceParticipants[activeSpace.id]) {
      return {
        observers: spaceParticipants[activeSpace.id]?.observers ?? {},
        participants: spaceParticipants[activeSpace.id]?.participants ?? {},
      };
    }
    return {
      observers: {},
      participants: {},
    };
  }, [activeSpace, spaceParticipants]);

  const {
    isJoiningSpace: joining,
    isPendingObservingSpace: pendingObserve,
    joinSpaceError,
    observeSpaceError,
    onJoinSpace,
    onLeaveSpace,
    onObserveSpace,
    onStopObservingSpace,
    onJoinSpaceAv,
  } = useRaceSpaceActions();

  return {
    joining: joining || pendingObserve,
    error: joinSpaceError || observeSpaceError,
    activeSpace,
    participants,
    observers,
    onJoinSpace,
    onLeaveSpace,
    onObserveSpace,
    onStopObservingSpace,
    onJoinSpaceAv,
  };
}
