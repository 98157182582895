import { useCallback, useEffect, useRef, useState } from 'react';
import { useIsUserActive } from '~/hooks';

import { useWsRaceActions } from '~/module/api';
import { reportError } from '~/module/logging';
import { useAuth, useDevices, useDevSwitches } from '~/store/hooks';
import { Device } from '~/store/slices';

import { Controllable } from '../ble/Controllable';
import { HRM } from '../ble/HRM';

export type ConnectedDevicesContext = {
  controllable: Device | null;
  controllableConnected: boolean;
  power: number;
  cadence: number;
  onConnectControllable: () => void;
  heartRateMonitor: Device | null;
  heartRateMonitorConnected: boolean;
  heartRate: number;
  onConnectHRM: () => void;
};

export const defaultState: ConnectedDevicesContext = {
  controllable: null,
  controllableConnected: false,
  power: 0,
  cadence: 0,
  onConnectControllable: () => {},
  heartRateMonitor: null,
  heartRateMonitorConnected: false,
  heartRate: 0,
  onConnectHRM: () => {},
};

// TODO - rework this structure and document the bluetooth hierarchy / flow

const controllable = new Controllable();
const hrm = new HRM();

export const useConnectedDevicesContext = (): ConnectedDevicesContext => {
  const { pairedControllable, onControllablePaired, pairedHRM, onHRMPaired } = useDevices();
  const { postWattage, postHeartRate, postCadence } = useWsRaceActions();
  const isUserActive = useIsUserActive();
  const { authenticated, authStateKnown } = useAuth();
  const { enableBob, bobWattage } = useDevSwitches();

  const initialControllableDeviceId = useRef(pairedControllable?.id);
  const controllableRef = useRef(controllable);
  const [power, setPower] = useState(0);
  const [cadence, setCadence] = useState(0);

  const initialHRMDeviceId = useRef(pairedHRM?.id);
  const hrmRef = useRef(hrm);
  const [heartRate, setHeartRate] = useState(0);

  // TODO - some kind of reconnection on mount
  const [isControllableConnected, setIsControllableConnected] = useState(false);
  const [isHRMConnected, setIsHRMConnected] = useState(false);

  const onConnectControllable = useCallback(async () => {
    await controllableRef.current.connect();
    await controllableRef.current.setSimMode();
    await controllableRef.current.getCharacteristics();
    onControllablePaired({
      // @ts-ignore
      id: controllableRef.current.device.id,
      // @ts-ignore
      name: controllableRef.current.device.name,
      lastPaired: new Date().getTime(),
    });
    setIsControllableConnected(true);
  }, [onControllablePaired]);

  const onConnectHRM = useCallback(async () => {
    await hrmRef.current.connect();
    await hrmRef.current.getCharacteristics();
    onHRMPaired({
      // @ts-ignore
      id: hrmRef.current.device.id,
      // @ts-ignore
      name: hrmRef.current.device.name,
      lastPaired: new Date().getTime(),
    });
    setIsHRMConnected(true);
  }, [onHRMPaired]);

  const reconnect = useCallback(async () => {
    if (pairedControllable?.id && pairedControllable?.id === initialControllableDeviceId.current) {
      console.log(`Trying to reconnect to ${pairedControllable.id}`);
      const connected = await controllableRef.current.reconnect(pairedControllable?.id);
      if (connected) {
        try {
          await controllableRef.current.getCharacteristics();
          onControllablePaired({
            // @ts-ignore
            id: controllableRef.current.device.id,
            // @ts-ignore
            name: controllableRef.current.device.name,
            lastPaired: new Date().getTime(),
          });
          setIsControllableConnected(true);
        } catch (err) {
          reportError('Error subscribing to controllable', err);
        }
      }
    }
    if (pairedHRM?.id && pairedHRM?.id === initialHRMDeviceId.current) {
      const connected = await hrmRef.current.reconnect(pairedHRM?.id);
      if (connected) {
        try {
          await hrmRef.current.getCharacteristics();
          onHRMPaired({
            // @ts-ignore
            id: controllableRef.current.device.id,
            // @ts-ignore
            name: controllableRef.current.device.name,
            lastPaired: new Date().getTime(),
          });
          setIsHRMConnected(true);
        } catch (err) {
          reportError('Error subscribing to hrm', err);
        }
      }
    }
  }, [pairedControllable?.id, onControllablePaired, pairedHRM?.id, onHRMPaired]);

  /**
   * Don't send these if not logged in to websocket
   * also, dont spam server if not connected to any controllables
   */
  useEffect(() => {
    if (pairedControllable) {
      controllableRef.current.subscribe(({ power, cadence }) => {
        setPower(power);
        setCadence(cadence);
      });
    }
  }, [pairedControllable]);

  useEffect(() => {
    if (pairedHRM) {
      hrmRef.current.subscribe(({ hr }) => {
        setHeartRate(hr);
      });
    }
  }, [pairedHRM]);

  const shouldPostMeasures = isUserActive && authenticated && authStateKnown;

  useEffect(() => {
    if (shouldPostMeasures) {
      postWattage(power);
    }
  }, [power, shouldPostMeasures, postWattage]);

  useEffect(() => {
    if (shouldPostMeasures) {
      postCadence(cadence);
    }
  }, [cadence, shouldPostMeasures, postCadence]);

  useEffect(() => {
    if (shouldPostMeasures) {
      postHeartRate(heartRate);
    }
  }, [heartRate, shouldPostMeasures, postHeartRate]);

  useEffect(() => {
    reconnect();
  }, [reconnect]);

  useEffect(() => {
    if (authStateKnown && enableBob) {
      const intervalId = setInterval(() => {
        setPower(bobWattage);
        postWattage(bobWattage);
        setIsControllableConnected(true);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      setPower(0);
      postWattage(0);
      setIsControllableConnected(false);
    }
  }, [postWattage, authStateKnown, enableBob, bobWattage]);

  return {
    controllable: pairedControllable,
    controllableConnected: isControllableConnected,
    power,
    cadence,
    onConnectControllable,
    heartRateMonitor: pairedHRM,
    heartRateMonitorConnected: isHRMConnected,
    heartRate,
    onConnectHRM,
  };
};
