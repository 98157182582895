import { useCallback, useEffect, useMemo } from 'react';

import { Box, Button, Spinner, Text } from '~/components/common';
import {
  useActiveSpace,
  useActiveSpaceAv,
  useAuth,
  useAvailableSpaces,
  useDevSwitches,
  useUser,
} from '~/store/hooks';
import { getScaledDistance } from '~/module/physics';
import { useAv } from '~/features/AudioVideo';
import { useWsApi, useWsAuthedStatus } from '~/module/api';

import { ActiveRace, RaceOver, RaceLobby } from './Race';
import { useRaceTime } from '../hooks';
import { RiderParticipantSummary } from '../Race.types';
import { JoinRaceCommsPopup } from './JoinRaceCommsPopup';

type SpaceProps = {
  id: string;
  onNavToHome: () => void;
  onNavToObserveSpace: (spaceId: string) => void;
  onNavToSpace: (spaceId: string) => void;
  onNavToRaceList: () => void;
};

export const Space = ({
  id,
  onNavToHome,
  onNavToSpace,
  onNavToObserveSpace,
  onNavToRaceList,
}: SpaceProps) => {
  const { user, userDistanceScale } = useUser();
  const { authenticated, authStateKnown } = useAuth();
  const wsAuthed = useWsAuthedStatus(useWsApi());
  const { activeSpace, participants, observers, joining, error, onJoinSpace, onLeaveSpace } =
    useActiveSpace();
  const { raceHasStarted, raceStartingSoon, raceHasEnded, raceDuration } = useRaceTime();
  const { enableAv } = useDevSwitches();
  const { availableSpaces } = useAvailableSpaces();
  const { appState } = useAv();
  const { alwaysJoinActiveSpaceAv } = useActiveSpaceAv();

  const activeSpaceId = activeSpace?.id;
  const canJoinSpace = authStateKnown && authenticated && wsAuthed;
  const isSpaceStateReady = !!activeSpace && activeSpace.id === id;
  const useDistanceScale = activeSpace?.details.useDistanceScale;

  const ridersSummary = useMemo((): Array<RiderParticipantSummary> => {
    if (activeSpace) {
      const { raceEndRiderSummary } = activeSpace;
      return raceEndRiderSummary.map((rider) => {
        const p = participants[rider.id];
        return {
          ...rider,
          ...p,
          assignedColor: p?.riderColour,
          distanceDelta: useDistanceScale
            ? getScaledDistance(rider.distanceDelta, userDistanceScale)
            : rider.distanceDelta,
        };
      });
    }
    return [];
  }, [activeSpace, participants, userDistanceScale, useDistanceScale]);

  const leaveSpace = useCallback(() => {
    onNavToRaceList();
  }, [onNavToRaceList]);

  useEffect(() => {
    if (canJoinSpace && id) {
      onJoinSpace(id, { joinAvCall: alwaysJoinActiveSpaceAv });
      return () => {
        onLeaveSpace(id);
      };
    }
  }, [onJoinSpace, onLeaveSpace, id, canJoinSpace, alwaysJoinActiveSpaceAv]);

  useEffect(() => {
    if (activeSpaceId) {
      return () => {
        onLeaveSpace(activeSpaceId);
      };
    }
  }, [activeSpaceId, onLeaveSpace]);

  const isAvReady = enableAv && appState > 0;

  if (joining) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        {error.code === 'ROOM_IS_CLOSED' ? (
          <Box>
            <Text color="status-error">Racing is underway.</Text>
            <Button onClick={() => onNavToObserveSpace(id)} label="Watch race" />
          </Box>
        ) : (
          <Text color="status-error">{error.message}</Text>
        )}
      </Box>
    );
  }

  if (!isSpaceStateReady) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      {raceHasEnded ? (
        <RaceOver
          ridersSummary={ridersSummary}
          raceDuration={raceDuration}
          observerUserId={user?.userId}
          hasAv={isAvReady}
          nextUpRacesTitle="Find your next race"
          nextUpRaces={availableSpaces.filter((s) => s.id !== activeSpace.id)}
          onGoBackTitle="Back to lobby"
          onGoBack={onNavToHome}
          onNavToSpace={onNavToSpace}
          onNavToObserveSpace={onNavToObserveSpace}
        />
      ) : raceHasStarted || raceStartingSoon ? (
        <ActiveRace hasAv={isAvReady} />
      ) : (
        <RaceLobby
          id={id}
          participants={participants}
          observers={observers}
          maxRiders={activeSpace.details.fieldSize}
          hasAv={isAvReady}
          leaveSpace={leaveSpace}
          {...activeSpace.details}
        />
      )}

      <JoinRaceCommsPopup enabled={enableAv && isSpaceStateReady} spaceId={id} />
    </Box>
  );
};
