import { useEffect, useRef, useState } from 'react';
import { JoinCommsPopup } from '~/features/AudioVideo/components/JoinCommsPopup';
import { useActiveSpace, useActiveSpaceAv } from '~/store/hooks';

export function JoinRaceCommsPopup({ spaceId, enabled }: { spaceId: string; enabled: boolean }) {
  const { onJoinSpaceAv } = useActiveSpace();
  const { isInRaceSpaceAv, alwaysJoinActiveSpaceAv, onSetAlwaysJoinActiveSpaceAv } =
    useActiveSpaceAv();

  const [showJoinRaceCommsPrompt, setShowJoinRaceCommsPrompt] = useState(false);
  const hasShownRaceCommsPromptRef = useRef(false);

  useEffect(() => {
    if (enabled && !isInRaceSpaceAv && !hasShownRaceCommsPromptRef.current) {
      hasShownRaceCommsPromptRef.current = true;
      setShowJoinRaceCommsPrompt(true);
    }
  }, [enabled, isInRaceSpaceAv]);

  if (!enabled || alwaysJoinActiveSpaceAv) {
    return null;
  }

  return (
    <JoinCommsPopup
      visible={showJoinRaceCommsPrompt}
      onConfirm={({ alwaysJoin }) => {
        onJoinSpaceAv(spaceId);
        setShowJoinRaceCommsPrompt(false);
        onSetAlwaysJoinActiveSpaceAv({ alwaysJoin });
      }}
      onCancel={({ alwaysJoin }) => {
        setShowJoinRaceCommsPrompt(false);
        onSetAlwaysJoinActiveSpaceAv({ alwaysJoin });
      }}
      onRequestClose={() => setShowJoinRaceCommsPrompt(false)}
    />
  );
}
