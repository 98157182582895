import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useWsSpaceActions } from '~/module/api/hooks/useWsSpaceActions';
import { useUser } from '~/store/hooks';
import { spacesActions } from '~/store/slices';

export const useRaceSpaceActions = () => {
  const dispatch = useDispatch();
  const { userId } = useUser();

  const { onJoinSpace, onLeaveSpace, onObserveSpace, onStopObservingSpace, ...spaceActions } =
    useWsSpaceActions();

  const joinSpace = useCallback(
    async (spaceId: string, opts: { joinAvCall: boolean }) => {
      const resp = await onJoinSpace(spaceId, 'race');
      // ts inference
      if (resp.spaceKind === 'race') {
        dispatch(
          spacesActions.onSetActiveSpace({
            id: spaceId,
            isInAvCall: opts.joinAvCall,
            details: resp.details,
            observingUserId: userId,
          }),
        );
      }
    },
    [dispatch, onJoinSpace, userId],
  );

  const leaveSpace = useCallback(
    async (spaceId: string) => {
      onLeaveSpace(spaceId);
      dispatch(spacesActions.onLeaveSpace({ spaceId, userId }));
    },
    [dispatch, onLeaveSpace, userId],
  );

  const observeSpace = useCallback(
    async (spaceId: string, opts: { joinAvCall: boolean; observingUserId?: string }) => {
      const resp = await onObserveSpace(spaceId);
      const payload: any = {
        id: spaceId,
        isInAvCall: opts.joinAvCall,
        details: resp.details,
        observingUserId: undefined,
      };
      if (opts.observingUserId) {
        payload.observingUserId = opts.observingUserId;
      }
      dispatch(spacesActions.onSetActiveSpace(payload));
    },
    [dispatch, onObserveSpace],
  );

  const stopObservingSpace = useCallback(
    async (spaceId: string) => {
      onStopObservingSpace(spaceId);
      dispatch(spacesActions.onLeaveSpace({ spaceId, userId }));
    },
    [dispatch, onStopObservingSpace, userId],
  );

  const onJoinSpaceAv = useCallback(
    (spaceId: string) => {
      dispatch(
        spacesActions.onSetActiveSpaceAv({
          id: spaceId,
          spaceKind: 'race',
        }),
      );
    },
    [dispatch],
  );

  return {
    onJoinSpace: joinSpace,
    onLeaveSpace: leaveSpace,
    onObserveSpace: observeSpace,
    onStopObservingSpace: stopObservingSpace,
    onJoinSpaceAv,
    ...spaceActions,
  };
};
