import { FC } from 'react';

import { useUser } from '~/store/hooks';
import { useAvailableSpaces as useSpaces } from '~/store/hooks/useAvailableSpaces';
import { RaceSpace } from '~/store/slices';

import { AvailableSpaces } from './AvailableSpaces';
import { Box, Text } from '~/components/common';

type FindARaceSceneProps = {
  onNavToSpace: (spaceId: string) => void;
  onNavToObserveSpace: (spaceId: string) => void;
};

type FindARaceProps = FindARaceSceneProps & {
  userDistanceScale: number;
  currentActiveRace?: string | null;
  availableSpaces: RaceSpace[];
};

export function FindARace({ ...props }: FindARaceProps & FindARaceSceneProps) {
  return (
    <Box>
      <Box>
        <Text tag="h3">Upcoming Races</Text>
      </Box>
      <AvailableSpaces {...props} />
    </Box>
  );
}

function useFindARace(props: FindARaceSceneProps) {
  const { availableSpaces } = useSpaces();
  const { userDistanceScale, currentActiveRace } = useUser();

  return {
    availableSpaces,
    currentActiveRace,
    userDistanceScale,
  };
}

const FindARaceConnected: FC<FindARaceSceneProps> = (sceneProps) => {
  const props = useFindARace(sceneProps);
  return <FindARace {...props} {...sceneProps} />;
};

export default FindARaceConnected;
