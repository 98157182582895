export const absoluteUrls = {
  unauthenticatedIndex: '/',
  home: '/',
  notFound: '/404',
  resetPassword: '/reset-password',
  signIn: '/login',
  signUp: '/signup',
  findRace: '/races',
  createSpace: '/new',
  userProfile: '/user/profile',
  userRaceHistory: '/user/history',
  userPower: '/user/power',
  userStravaSettings: '/user/strava',
  stravaAuth: '/stravaAuth',
  tournaments: '/tournaments',
};

export const dynamicUrls = {
  space: '/space/:spaceId', // TODO - rename to races/
  observeSpace: '/observe-space/:spaceId',
  userRaceHistoryItem: '/user/history/:raceId',
  tournament: '/tournaments/:tournamentId',
};

export type DynamicURLTypes = {
  space: {
    spaceId: string;
  };
  observeSpace: {
    spaceId: string;
  };
  userRaceHistoryItem: {
    raceId: string;
  };
  tournament: {
    tournamentId: string;
  };
};

export const getPathToSpace = ({ spaceId }: DynamicURLTypes['space']): string =>
  dynamicUrls.space.replace(':spaceId', `${spaceId.toLowerCase()}`);

export const getPathToTournament = ({ tournamentId }: DynamicURLTypes['tournament']): string =>
  dynamicUrls.tournament.replace(':tournamentId', `${tournamentId}`);

export const getPathToObserveSpace = ({
  spaceId,
  backTo,
}: DynamicURLTypes['observeSpace'] & { backTo?: string }): string =>
  dynamicUrls.observeSpace.replace(':spaceId', `${spaceId.toLowerCase()}`) +
  (backTo ? `?backTo=${encodeURIComponent(backTo)}` : ``);

export const getPathToRaceHistoryItem = ({
  raceId,
}: DynamicURLTypes['userRaceHistoryItem']): string =>
  dynamicUrls.userRaceHistoryItem.replace(':raceId', `${raceId}`);
