import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';

import { BasicSpaceDetails, RaceSpaceDetails, SpaceKind, spacesActions } from '~/store/slices';
import {
  CreateSpaceRequestData,
  CreateSpaceResponseData,
  JoinSpaceRequestData,
  JoinSpaceResponseData,
  LeaveSpaceRequestData,
  LeaveSpaceResponseData,
  ObserveSpaceRequestData,
  ObserveSpaceResponseData,
  StopObservingSpaceRequestData,
  StopObservingSpaceResponseData,
} from '~/module/api/api.types';
import { transformRaceSpaceDetails } from '~/subscriptions/transforms';

import { ApiError, useWsSyncRequest } from './useWsSyncRequest';

type JoinSpaceReturnType =
  | { spaceKind: 'race'; details: RaceSpaceDetails }
  | { spaceKind: 'basic'; details: BasicSpaceDetails };

interface UseSpaceActions {
  // join
  onJoinSpace: (spaceUuid: string, spaceKind: SpaceKind) => Promise<JoinSpaceReturnType>;
  isJoiningSpace: boolean;
  joinSpaceError: Maybe<ApiError>;
  // leave
  onLeaveSpace: (spaceUuid: string) => Promise<LeaveSpaceResponseData>;
  isLeavingSpace: boolean;
  leaveSpaceError: Maybe<ApiError>;
  // create
  onCreateSpace: (options: CreateSpaceRequestData) => Promise<CreateSpaceResponseData>;
  isCreatingSpace: boolean;
  createSpaceError: Maybe<ApiError>;
  // observe
  onObserveSpace: (spaceUuid: string) => Promise<{ spaceKind: 'race'; details: RaceSpaceDetails }>;
  isPendingObservingSpace: boolean;
  observeSpaceError: Maybe<ApiError>;
  // stopObserving
  onStopObservingSpace: (spaceUuid: string) => Promise<LeaveSpaceResponseData>;
  isStoppingObservingSpace: boolean;
  stopObservingSpaceError: Maybe<ApiError>;
}

export const useWsSpaceActions = (): UseSpaceActions => {
  const dispatch = useDispatch();

  const {
    isLoading: isJoiningSpace,
    error: joinSpaceError,
    send: joinSpace,
  } = useWsSyncRequest<JoinSpaceRequestData, JoinSpaceResponseData>({ method: 'joinSpace' });

  const {
    isLoading: isLeavingSpace,
    error: leaveSpaceError,
    send: leaveSpace,
  } = useWsSyncRequest<LeaveSpaceRequestData, LeaveSpaceResponseData>({ method: 'leaveSpace' });

  const {
    isLoading: isCreatingSpace,
    error: createSpaceError,
    send: createSpace,
  } = useWsSyncRequest<CreateSpaceRequestData, CreateSpaceResponseData>({ method: 'createSpace' });

  const {
    isLoading: isPendingObservingSpace,
    error: observeSpaceError,
    send: observeSpace,
  } = useWsSyncRequest<ObserveSpaceRequestData, ObserveSpaceResponseData>({
    method: 'observeSpace',
  });

  const {
    isLoading: isStoppingObservingSpace,
    error: stopObservingSpaceError,
    send: stopObservingSpace,
  } = useWsSyncRequest<StopObservingSpaceRequestData, StopObservingSpaceResponseData>({
    method: 'stopObservingSpace',
  });

  const onJoinSpace = useCallback(
    async (spaceUuid: string, spaceKind: SpaceKind): Promise<JoinSpaceReturnType> => {
      const response = await joinSpace({
        spaceUUID: spaceUuid,
        spaceKind,
      });
      if (
        (response && response.data && response.data.valid) ||
        response?.data?.invalid_reason === 'ALREADY_IN_ROOM'
      ) {
        if (spaceKind === 'race') {
          return { spaceKind: 'race', details: transformRaceSpaceDetails(response.data.details) };
        } else {
          return { spaceKind: 'basic', details: {} };
        }
      } else {
        throw new Error(
          `joinSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown error',
          )}]`,
        );
      }
    },
    [joinSpace],
  );

  const onLeaveSpace = useCallback(
    async (spaceUuid: string) => {
      const response = await leaveSpace({
        spaceUUID: spaceUuid,
      });
      if (
        (response && response.data && response.data.valid) ||
        response?.data?.invalid_reason === 'NOT_IN_ROOM'
      ) {
        return response.data;
      } else {
        throw new Error(
          `leaveSpace - Invalid response [${_get(response, 'data.invalid_reason', 'unknown err')}]`,
        );
      }
    },
    [leaveSpace],
  );

  const onObserveSpace = useCallback(
    async (spaceUuid: string): Promise<{ spaceKind: 'race'; details: RaceSpaceDetails }> => {
      const response = await observeSpace({
        spaceUUID: spaceUuid,
        spaceKind: 'race',
      });
      if (
        (response && response.data && response.data.valid) ||
        response?.data?.invalid_reason === 'ALREADY_IN_ROOM'
      ) {
        return { spaceKind: 'race', details: transformRaceSpaceDetails(response.data.details) };
      } else {
        throw new Error(
          `observeSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown error',
          )}]`,
        );
      }
    },
    [observeSpace],
  );

  const onStopObservingSpace = useCallback(
    async (spaceUuid: string) => {
      const response = await stopObservingSpace({
        spaceUUID: spaceUuid,
      });
      if (response && response.data && response.data.valid) {
        return response.data;
      } else {
        throw new Error(
          `stopObservingSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown err',
          )}]`,
        );
      }
    },
    [stopObservingSpace],
  );

  const onCreateSpace = useCallback(
    async (options: CreateSpaceRequestData) => {
      const response = await createSpace(options);
      if (response && response.data && response.data.spaceUUID) {
        return response.data;
      } else {
        throw new Error(
          `createSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown err',
          )}]`,
        );
      }
    },
    [createSpace],
  );

  return {
    onJoinSpace,
    isJoiningSpace,
    joinSpaceError,
    onLeaveSpace,
    isLeavingSpace,
    leaveSpaceError,
    onCreateSpace,
    isCreatingSpace,
    createSpaceError,
    onObserveSpace,
    isPendingObservingSpace,
    observeSpaceError,
    onStopObservingSpace,
    isStoppingObservingSpace,
    stopObservingSpaceError,
  };
};
