import { useFirestoreTournamentsListener } from '~/module/firebase/firestore/useFirestoreTournaments';
import { useTournamentState } from '../../hooks';
import { useEffect } from 'react';
import { useActiveSpace, useRaceState, useUser } from '~/store/hooks';
import { useConnectedDevices } from '~/module/connectedDevices';

export const useTournament = ({ tournamentId }: { tournamentId: string }) => {
  useFirestoreTournamentsListener();
  const { currentRaceId, ...tournamentState } = useTournamentState(tournamentId);
  const { userId } = useUser();
  const { activeSpace, onJoinSpace, onLeaveSpace } = useActiveSpace();
  const { raceStartTime, details } = useRaceState();
  const { controllableConnected } = useConnectedDevices();

  const hasJoinedRace = !!currentRaceId && !!activeSpace?.id && activeSpace.id === currentRaceId;

  useEffect(() => {
    if (currentRaceId && !hasJoinedRace) {
      // TODO - give users the option to join the call or not
      onJoinSpace(currentRaceId, { joinAvCall: true });
    }
  }, [currentRaceId, hasJoinedRace, onJoinSpace, onLeaveSpace]);

  return {
    ...tournamentState,
    userId,
    currentRaceId,
    hasJoinedRace,
    raceState: details?.state ?? null,
    raceStartTime,
    isControllableConnected: controllableConnected,
  };
};
