import { useCallback, useEffect } from 'react';
import { getAuth, onIdTokenChanged, User, signOut } from 'firebase/auth';

import { useActiveSpace, useAuth } from '~/store/hooks';
import { useWsUser } from '~/module/api/hooks/useWsUser';
import { useWsApi } from '~/module/api';
import { setFirebaseUser } from '~/module/firebase';
import { setLogUser } from '~/module/logging';

const useAuthListener = () => {
  const { onSetAuth } = useAuth();

  const onAuthChangeCallback = useCallback(
    async (user: User | null) => {
      setFirebaseUser(user);
      setLogUser(user?.uid ?? null);
      if (user && user.isAnonymous) {
        signOut(getAuth());
        onSetAuth({ id: undefined });
      } else if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const token = await user.getIdToken();
        onSetAuth({
          id: user.uid,
          isAnonymous: user.isAnonymous,
          idToken: token,
          email: user.email || undefined,
        });
      } else {
        // User is signed out
        onSetAuth({ id: undefined });
      }
    },
    [onSetAuth],
  );

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onIdTokenChanged(auth, onAuthChangeCallback);
    return () => {
      unsubscribe();
    };
  }, [onAuthChangeCallback]);
};

export const AuthListener = () => {
  useAuthListener();

  const api = useWsApi();
  const { onFetchUser } = useWsUser();
  const { userIdToken } = useAuth();
  const { activeSpace } = useActiveSpace();
  const activeSpaceId = activeSpace?.id ?? '';

  // TODO - also setAuthToken when reconnecting to websocket

  const handleWsAuth = useCallback(
    async (token?: string) => {
      await api.setAuthToken(token);
      if (token) {
        onFetchUser();
      }
    },
    [api, onFetchUser],
  );

  useEffect(() => {
    handleWsAuth(userIdToken);
  }, [userIdToken, handleWsAuth]);

  useEffect(() => {
    if (api.isAuthenticated) {
      onFetchUser();
    }
  }, [api, activeSpaceId, onFetchUser]);

  return null;
};
