import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalSessionId } from '@daily-co/daily-react';
import { CheckBox } from 'grommet';

import { Box, Button, Spinner, Text } from '~/components/common';
import { spacesActions } from '~/store/slices';
import { useCritzGroupSpace } from '~/features/GroupSpaces/hooks/useCritzGroupSpace';
import { UserHandle } from '~/components/UserHandle';
import { useActiveSpace, useActiveSpaceAv, useUser } from '~/store/hooks';

import { useAvSpaceParticipants } from '../hooks/useAvSpaceParticipants';
import { ParticipantVideo, ParticipantVideoTile } from './ParticipantVideo';
import { AvControlsLocal } from './AvControls';
import { useAv } from '../provider';
import { SelectAvDevices } from './SelectAvDevices';

export const CommsChannelsManager = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();

  const { appState } = useAv();
  const { user, userId } = useUser();
  const { participants: avSpaceParticipants } = useAvSpaceParticipants();
  const {
    isInActiveSpaceAv,
    isInGroupSpaceAv,
    isInRaceSpaceAv,
    alwaysJoinActiveSpaceAv,
    onSetAlwaysJoinActiveSpaceAv,
  } = useActiveSpaceAv();
  const {
    activeSpace,
    participants: activeSpaceParticipants,
    observers: activeSpaceObservers,
  } = useActiveSpace();

  const [showDevicesConfig, setShowDevicesConfig] = useState(false);

  const isInActiveSpace =
    !!activeSpace?.id && (!!activeSpaceParticipants[userId] || !!activeSpaceObservers[userId]);

  const localSessionId = useLocalSessionId();

  const { onJoinGroup, onLeaveGroup } = useCritzGroupSpace();

  const onJoinRaceComms = useCallback(
    (id: string) => {
      dispatch(spacesActions.onSetActiveSpaceAv({ id, spaceKind: 'race' }));
    },
    [dispatch],
  );

  const onLeaveRaceComms = useCallback(() => {
    dispatch(spacesActions.onSetActiveSpaceAv(null));
  }, [dispatch]);

  return (
    <Box>
      <Box pad="medium" gap="medium" direction="row">
        <Box
          width="medium"
          background={isInGroupSpaceAv ? 'status-ok' : 'background-contrast'}
          justify="center"
          align="center"
          pad="medium"
          gap="medium"
          round
          flexNum={1}
          focusIndicator={false}
        >
          <Text size="large">Critz Team Comms</Text>
          <Button
            label={isInGroupSpaceAv ? 'Leave Call' : 'Join Call'}
            size="large"
            primary
            onClick={!isInGroupSpaceAv ? () => onJoinGroup({ isInAvCall: true }) : onLeaveGroup}
          />
        </Box>
        <Box
          width="medium"
          background={isInRaceSpaceAv ? 'status-ok' : 'background-contrast'}
          justify="center"
          align="center"
          pad="medium"
          gap="medium"
          round
          flexNum={1}
          focusIndicator={false}
        >
          {isInActiveSpace ? (
            <Box gap="medium" align="center">
              <Box align="center">
                <Text size="large">Race Comms</Text>
                <Text uppercase>{activeSpace.id}</Text>
              </Box>
              <Button
                label={isInRaceSpaceAv ? 'Leave Call' : 'Join Call'}
                size="large"
                primary
                onClick={
                  !isInRaceSpaceAv ? () => onJoinRaceComms(activeSpace.id) : onLeaveRaceComms
                }
              />
            </Box>
          ) : (
            <Box gap="medium" align="center">
              <Text size="large">Race Comms</Text>
              <Text uppercase weight="lighter">
                No active race selected.
              </Text>
            </Box>
          )}
          <Box>
            <CheckBox
              label="Always join race comms"
              checked={alwaysJoinActiveSpaceAv}
              onChange={(e) => onSetAlwaysJoinActiveSpaceAv({ alwaysJoin: e.target.checked })}
            />
          </Box>
        </Box>
      </Box>

      {isInActiveSpaceAv && !!user && (
        <Box>
          {appState < 0 && (
            <Box align="center" pad="medium">
              <Text color="error">Error connecting to call.</Text>
            </Box>
          )}
          {appState === 0 && (
            <Box align="center" pad="medium" gap="small">
              <Text>Connecting to call...</Text>
              <Spinner />
            </Box>
          )}
          {appState > 0 && (
            <Box>
              {showDevicesConfig ? (
                <SelectAvDevices />
              ) : (
                <Box direction="row" wrap gap="medium">
                  <Box>
                    <UserHandle {...user} accent />
                    <ParticipantVideo
                      id={localSessionId}
                      mirrorVideo
                      videoEnabled
                      width="small"
                      height="small"
                      round
                    />
                    <Box align="center" margin={{ vertical: 'small' }}>
                      <AvControlsLocal />
                    </Box>
                    <Button
                      label="Settings"
                      secondary
                      onClick={() => setShowDevicesConfig(true)}
                      size="small"
                    />
                  </Box>
                  {avSpaceParticipants.map((p) => {
                    return (
                      <Box key={p.id}>
                        <Box>
                          <UserHandle {...p} />
                        </Box>
                        {p.sessionId ? (
                          <ParticipantVideo
                            id={p.sessionId}
                            videoEnabled
                            width="small"
                            height="small"
                          />
                        ) : (
                          <ParticipantVideoTile connected={false} width="small" height="small" />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}

      <Box align="center" margin={{ top: 'medium' }}>
        <Button
          onClick={showDevicesConfig ? () => setShowDevicesConfig(false) : onClose}
          label={showDevicesConfig ? 'Back' : 'Close'}
        />
      </Box>
    </Box>
  );
};
