import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  DynamicURLTypes,
  absoluteUrls,
  getPathToObserveSpace,
  getPathToSpace,
} from '~/navigation/Navigation.paths';
import { SceneComponent } from '~/navigation/Navigation.types';
import { Box } from '~/components/common';
import { ObserveSpace } from '~/features/Spaces/components/ObserveSpace';

export const ObserveSpaceScene: SceneComponent<{}> = () => {
  const { spaceId: spaceIdUrl } = useParams<DynamicURLTypes['observeSpace']>();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const spaceId = spaceIdUrl?.toLowerCase();
  const backTo = search.get('backTo');

  if (!spaceId) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{spaceId}</title>
      </Helmet>
      <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <ObserveSpace
          id={spaceId}
          onGoBack={() => {
            if (backTo) {
              navigate(backTo);
            } else {
              navigate(absoluteUrls.findRace);
            }
          }}
          onNavToObserveSpace={(spaceId) => navigate(getPathToObserveSpace({ spaceId }))}
          onNavToSpace={(spaceId) => navigate(getPathToSpace({ spaceId }))}
        />
      </Box>
    </>
  );
};
