import { useCallback, useEffect, useMemo } from 'react';

import { Box, Spinner, Text } from '~/components/common';
import { useWsApi, useWsAuthedStatus } from '~/module/api';
import { useActiveSpace, useActiveSpaceAv, useAuth, useDevSwitches } from '~/store/hooks';
import { useAv } from '~/features/AudioVideo';

import { useRaceTime } from '../hooks';
import { ActiveRace, RaceLobby, RaceOver } from './Race';
import { JoinRaceCommsPopup } from './JoinRaceCommsPopup';
import { RiderParticipantSummary } from '../Race.types';

type ObserveSpaceProps = {
  id: string;
  onGoBack: () => void;
  onNavToSpace: (spaceId: string) => void;
  onNavToObserveSpace: (spaceId: string) => void;
};

export const ObserveSpace = ({
  id,
  onGoBack,
  onNavToSpace,
  onNavToObserveSpace,
}: ObserveSpaceProps) => {
  const { authenticated, authStateKnown } = useAuth();
  const wsAuthed = useWsAuthedStatus(useWsApi());
  const {
    activeSpace,
    participants,
    observers,
    joining,
    error,
    onObserveSpace,
    onStopObservingSpace,
  } = useActiveSpace();
  const { enableAv } = useDevSwitches();
  const { appState } = useAv();
  const { alwaysJoinActiveSpaceAv } = useActiveSpaceAv();

  const { raceHasStarted, raceStartingSoon, raceHasEnded, raceDuration } = useRaceTime();

  const ridersSummary = useMemo((): Array<RiderParticipantSummary> => {
    if (activeSpace) {
      const { raceEndRiderSummary } = activeSpace;
      return raceEndRiderSummary.map((rider) => {
        const p = participants[rider.id];
        return {
          ...rider,
          ...p,
          assignedColor: p?.riderColour,
        };
      });
    }
    return [];
  }, [activeSpace, participants]);

  const isSpaceStateReady = !!activeSpace && activeSpace.id === id;
  const canJoinSpace = authStateKnown && authenticated && wsAuthed;

  const isAvReady = enableAv && appState > 0;

  const leaveSpace = useCallback(() => {
    onGoBack();
  }, [onGoBack]);

  useEffect(() => {
    if (canJoinSpace) {
      onObserveSpace(id, { joinAvCall: alwaysJoinActiveSpaceAv });
      return () => {
        onStopObservingSpace(id);
      };
    }
  }, [onObserveSpace, onStopObservingSpace, id, canJoinSpace, alwaysJoinActiveSpaceAv]);

  if (joining) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Text color="status-error">{error.message}</Text>
      </Box>
    );
  }

  if (!isSpaceStateReady) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      {raceHasEnded ? (
        <RaceOver
          ridersSummary={ridersSummary}
          raceDuration={raceDuration}
          hasAv={isAvReady}
          nextUpRacesTitle="Find your next race"
          nextUpRaces={[]}
          onGoBackTitle="Back to lobby"
          onGoBack={onGoBack}
          onNavToSpace={onNavToSpace}
          onNavToObserveSpace={onNavToObserveSpace}
        />
      ) : raceHasStarted || raceStartingSoon ? (
        <ActiveRace hasAv={isAvReady} isObserving onGoBack={onGoBack} />
      ) : (
        <RaceLobby
          id={id}
          isObserving
          participants={participants}
          observers={observers}
          maxRiders={activeSpace.details.fieldSize}
          hasAv={isAvReady}
          leaveSpace={leaveSpace}
          {...activeSpace.details}
        />
      )}

      <JoinRaceCommsPopup enabled={enableAv && isSpaceStateReady} spaceId={id} />
    </Box>
  );
};
