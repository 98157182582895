import { useParticipantProperty } from '@daily-co/daily-react';

import { AudioTrack } from './AudioTrack';

export type ParticipantAudioProps = {
  id: string;
  audioEnabled?: boolean;
};

export const ParticipantAudio = ({ id, audioEnabled = true }: ParticipantAudioProps) => {
  const participantAudioState = useParticipantProperty(id, 'tracks.audio.state');
  const audioDisabled = participantAudioState === 'off';

  if (!audioEnabled || audioDisabled) {
    return null;
  }

  return <AudioTrack id={id} />;
};
