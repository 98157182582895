import { RecoilRoot } from 'recoil';

import { StoreProvider } from './store';

import { SubscriptionManager } from './subscriptions';
import { SceneManager } from './navigation/SceneManager';
import { ThemeProvider } from './theme';
import { ConnectedDevicesProvider } from './module/connectedDevices/context';
import { AuthListener } from './features/Auth';
import {
  useFirestoreUserDataListener,
  useFirestoreUserRaceHistoryListener,
} from './module/firebase';
import { AvProvider } from './features/AudioVideo';

function GlobalListeners() {
  useFirestoreUserDataListener();
  useFirestoreUserRaceHistoryListener();
  return (
    <>
      <SubscriptionManager />
      <AuthListener />
    </>
  );
}

function App() {
  return (
    <StoreProvider>
      <RecoilRoot>
        <AvProvider>
          <ConnectedDevicesProvider>
            <ThemeProvider>
              <GlobalListeners />
              <SceneManager />
            </ThemeProvider>
          </ConnectedDevicesProvider>
        </AvProvider>
      </RecoilRoot>
    </StoreProvider>
  );
}

export default App;
