import { PropsWithChildren } from 'react';
import { useParticipantProperty, useNetwork } from '@daily-co/daily-react';
import { BoxProps } from 'grommet';

import { Box, Icon, Text } from '~/components/common';

import { VideoTile } from './VideoTile';

type ParticipantVideoTileProps = {
  connected: boolean;
  audioEnabled?: boolean;
  videoEnabled?: boolean;
  connectionQualityPoor?: boolean;
  quality?: number;
  threshold?: string;
};

export function ParticipantVideoTile({
  connected,
  audioEnabled = true,
  videoEnabled = true,
  connectionQualityPoor = false,
  children,
  ...boxProps
}: PropsWithChildren<ParticipantVideoTileProps & BoxProps>) {
  const hasWarnings = !audioEnabled || !videoEnabled || !connected;
  return (
    <Box
      {...boxProps}
      background="background-front"
      overflow="hidden"
      round
      style={{ position: 'relative' }}
    >
      {children}

      {hasWarnings && (
        <Box
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          align="center"
          justify="end"
          pad="small"
        >
          {connected ? (
            <Box gap="small" align="center">
              {!audioEnabled && (
                <Box direction="row" align="center">
                  <Icon iconName="microphone" color="text-weak" size="small" />
                  <Text size="xsmall" margin={{ left: 'xsmall' }}>
                    Muted
                  </Text>
                </Box>
              )}
              {!videoEnabled && (
                <Box direction="row" align="center">
                  <Icon iconName="camera" color="text-weak" size="small" />
                  <Text size="xsmall" margin={{ left: 'xsmall' }}>
                    Video disabled
                  </Text>
                </Box>
              )}
              {connectionQualityPoor && (
                <Box>
                  <Text size="xsmall">Connection Unstable</Text>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <Text size="xsmall">Connecting</Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export type ParticipantVideoProps = {
  id: string;
  videoEnabled?: boolean;
  mirrorVideo?: boolean;
  showQuality?: boolean;
};

export const ParticipantVideo = ({
  id,
  videoEnabled = true,
  mirrorVideo = false,
  showQuality = false,
  ...boxProps
}: ParticipantVideoProps & BoxProps) => {
  const participantAudioState = useParticipantProperty(id, 'tracks.audio.state');
  const participantVideoState = useParticipantProperty(id, 'tracks.video.state');

  const { threshold } = useNetwork();

  const audioDisabled = participantAudioState === 'off';
  const videoDisabled = participantVideoState === 'off';

  return (
    <ParticipantVideoTile
      videoEnabled={!videoDisabled}
      audioEnabled={!audioDisabled}
      connectionQualityPoor={threshold === 'very-low'}
      connected
      {...boxProps}
    >
      {videoEnabled && !videoDisabled && (
        <VideoTile
          id={id}
          style={{
            objectFit: 'cover',
            transform: mirrorVideo ? 'rotateY(180deg)' : '',
          }}
        />
      )}
    </ParticipantVideoTile>
  );
};
