import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _uniqBy from 'lodash/uniqBy';

import { useUser } from '~/store/hooks';
import { spacesSelectors } from '~/store/slices';

import { useCallData } from './useCallData';

export const useAvSpaceParticipants = () => {
  const { userId } = useUser();

  const activeSpaceAv = useSelector(spacesSelectors.getActiveSpaceAv);
  const spaceParticipants = useSelector(spacesSelectors.getSpaceParticipants);

  const { callObjectRef, callParticipants } = useCallData();

  const userSessions = useMemo(() => {
    if (callObjectRef.current && callParticipants.length) {
      const avParticipantsByUserId = callParticipants.reduce((byUser, participant) => {
        return {
          ...byUser,
          [participant.user_id]: participant.session_id,
        };
      }, {} as Record<string, string>);
      return avParticipantsByUserId;
    }
    return {};
  }, [callParticipants, callObjectRef]);

  const avSpaceParticipants = useMemo(() => {
    if (activeSpaceAv && spaceParticipants[activeSpaceAv.id]) {
      return _uniqBy(
        [
          ...Object.values(spaceParticipants[activeSpaceAv.id].participants),
          ...Object.values(spaceParticipants[activeSpaceAv.id].observers),
        ],
        'id',
      );
    }
    return [];
  }, [spaceParticipants, activeSpaceAv]);

  const participants = useMemo(() => {
    return avSpaceParticipants
      .filter((p) => p.id !== userId)
      .map((p) => {
        return {
          ...p,
          sessionId: (userSessions[p.id] ?? null) as string | null,
        };
      });
  }, [avSpaceParticipants, userId, userSessions]);

  return {
    participants,
  };
};
