import { useCallback } from 'react';

import { useWsSpaceActions } from '~/module/api/hooks/useWsSpaceActions';

export const useGroupSpaceActions = (groupSpaceId: string) => {
  const { onJoinSpace, onLeaveSpace, ...spaceActions } = useWsSpaceActions();

  const joinSpace = useCallback(() => {
    return onJoinSpace(groupSpaceId, 'basic');
  }, [groupSpaceId, onJoinSpace]);

  const leaveSpace = useCallback(() => {
    return onLeaveSpace(groupSpaceId);
  }, [groupSpaceId, onLeaveSpace]);

  return {
    onJoinSpace: joinSpace,
    onLeaveSpace: leaveSpace,
    ...spaceActions,
  };
};
