import { useDispatch, useSelector } from 'react-redux';
import { spacesActions, spacesSelectors } from '../slices';
import { useCallback } from 'react';

export const useAvControls = () => {
  const dispatch = useDispatch();

  const audioEnabled = useSelector(spacesSelectors.getSendAudio);
  const videoEnabled = useSelector(spacesSelectors.getSendVideo);

  const onSetAudioEnabled = useCallback(
    ({ enabled }: { enabled: boolean }) => {
      dispatch(spacesActions.onSetSendAudio(enabled));
    },
    [dispatch],
  );
  const onSetVideoEnabled = useCallback(
    ({ enabled }: { enabled: boolean }) => {
      dispatch(spacesActions.onSetSendVideo(enabled));
    },
    [dispatch],
  );

  return {
    audioEnabled,
    videoEnabled,
    onSetAudioEnabled,
    onSetVideoEnabled,
  };
};
