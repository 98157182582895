import { RangeInput } from 'grommet';
import React, { FC } from 'react';
import { Box, Button, Text } from '~/components/common';
import { useDevSwitches } from '~/store/hooks';

const DevSwitch: FC<{ label: string; value: boolean; onToggle: () => void }> = ({
  label,
  value,
  onToggle,
}) => {
  return (
    <Box direction="row" justify="between" align="center">
      <Text size="xsmall">{label}</Text>
      <Button primary onClick={onToggle} label={value ? 'Yes' : 'No'} size="small" />
    </Box>
  );
};

export const DevMenu: FC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    enableBob,
    onToggleBob,
    bobWattage,
    onSetBobWattage,
    enableThreePostProcessing,
    onToggleThreePostProcessing,
    enableThreePerf,
    onToggleThreePerf,
    enableAv,
    onToggleAv,
  } = useDevSwitches();
  return (
    <Box width="small">
      <Box margin={{ bottom: 'small' }}>
        <Text size="medium" textAlign="center">
          Dev
        </Text>
      </Box>
      <Box gap="xsmall">
        <DevSwitch label="Enable Bob" value={enableBob} onToggle={onToggleBob} />
        {enableBob && (
          <Box margin={{ bottom: 'small' }}>
            <RangeInput
              value={bobWattage}
              min={0}
              step={1}
              max={1000}
              onChange={(e) => onSetBobWattage({ power: Number(e.target.value) })}
            />
            <Text textAlign="center">{bobWattage}W</Text>
          </Box>
        )}
        <DevSwitch
          label="Enable 3JS Post Processing"
          value={enableThreePostProcessing}
          onToggle={onToggleThreePostProcessing}
        />
        <DevSwitch label="Enable 3JS Perf" value={enableThreePerf} onToggle={onToggleThreePerf} />
        <DevSwitch label="Enable AudioVideo" value={enableAv} onToggle={onToggleAv} />
      </Box>

      <Box margin={{ top: 'medium' }}>
        <Button label="Close" onClick={onClose} secondary />
      </Box>
    </Box>
  );
};
