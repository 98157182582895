import { FC } from 'react';

import { Box, Icon } from '~/components/common';
import { useAvControls } from '~/store/hooks';
import { useAv } from '../provider';

export const AvControls: FC<{
  audioEnabled: boolean;
  videoEnabled: boolean;
  onToggleAudio?: (enabled: boolean) => void;
  onToggleVideo?: (enabled: boolean) => void;
}> = ({ audioEnabled, videoEnabled, onToggleAudio, onToggleVideo }) => {
  return (
    <Box direction="row" gap="xsmall">
      <Box
        onClick={onToggleAudio ? () => onToggleAudio(!audioEnabled) : undefined}
        round
        pad="small"
        focusIndicator={false}
        hoverIndicator={{ elevation: 'small' }}
      >
        <Icon iconName="microphone" color={audioEnabled ? 'accent-1' : 'text-weak'} />
      </Box>
      <Box
        onClick={onToggleVideo ? () => onToggleVideo(!videoEnabled) : undefined}
        round
        pad="small"
        focusIndicator={false}
        hoverIndicator={{ elevation: 'small' }}
      >
        <Icon iconName="camera" color={videoEnabled ? 'accent-1' : 'text-weak'} />
      </Box>
    </Box>
  );
};

export const AvControlsLocal = () => {
  const { appState } = useAv();
  const { audioEnabled, videoEnabled, onSetAudioEnabled, onSetVideoEnabled } = useAvControls();

  if (appState < 1) return null;

  return (
    <AvControls
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      onToggleAudio={(enabled: boolean) => {
        onSetAudioEnabled({ enabled });
      }}
      onToggleVideo={(enabled: boolean) => {
        onSetVideoEnabled({ enabled });
      }}
    />
  );
};
