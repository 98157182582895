import { v4 as uuidv4 } from 'uuid';

import { Socket } from '../Socket';
import {
  WSRequestMessages,
  WSMessageTypes,
  WsMessages,
  ApiRequest,
  ApiResponse,
  WSApiMessages,
  RaceDataMeasure,
  WsAsyncMethod,
  PingRequestData,
  PingResponseData,
  PangRequestData,
  PangResponseData,
  AuthRequestData,
  AuthResponseData,
} from '../api.types';
import {
  MessageListener,
  ClockListener,
  AuthListener,
  ClockInfo,
  DeRegisterFn,
  IsResponseFn,
} from './WSService.types';
import { WS_CONFIG } from '~/module/config';
import { reportError } from '~/module/logging';

const { SYNC_REQUEST_DEFAULT_TIMEOUT, PING_INTERVAL, PING_TIMEOUT } = WS_CONFIG;

export class WSService extends Socket {
  private pingIntervalId: Maybe<NodeJS.Timeout> = null;
  private syncRequestTimeout = SYNC_REQUEST_DEFAULT_TIMEOUT;

  private reconnectionListener: Maybe<DeRegisterFn> = null;
  private authListeners: AuthListener[] = [];
  private clockListeners: ClockListener[] = [];
  private lastClockState: ClockInfo = {
    latency: 0,
    clockOffset: 0,
  };

  private authToken?: string;
  public isAuthenticated: boolean = false;

  public startReconnectionListener() {
    this.stopReconnectionListener();

    let prevStatus: boolean;
    this.reconnectionListener = this.registerStatusListener((status) => {
      if (status !== prevStatus) {
        if (prevStatus === false && status === true) {
          this.onReconnect();
        } else if (prevStatus === true && status === false) {
          this.onDisconnect();
        }
        prevStatus = status;
      }
    });
  }

  public stopReconnectionListener() {
    if (this.reconnectionListener) {
      this.reconnectionListener();
    }
  }

  private async onReconnect() {
    console.log('Reconnected');
    if (this.isAuthenticated) {
      console.log('Re-authenticating after reconnection');
      await this.authenticate();
      console.log('Re-authenticated after reconnection');
    }
  }

  private onDisconnect() {
    console.log('Attempting to reconnect in 1s');
    setTimeout(() => {
      this.reconnect();
    }, 1000);
  }

  private async authenticate() {
    const authToken = this.authToken;
    if (authToken) {
      const authMsg = this.buildMessage({
        method: WSMessageTypes.auth,
        auth_token: this.authToken,
      });
      const response = await this.makeSynchronousRequest<AuthRequestData, AuthResponseData>(
        authMsg,
        5000,
      );
      if (response.status === 'OK') {
        this.isAuthenticated = true;
        this.notifyAuthListeners();
      } else {
        this.authToken = '';
        this.isAuthenticated = false;
        this.notifyAuthListeners();
      }
    } else {
      const wasAuthenticated = this.isAuthenticated;
      this.isAuthenticated = false;
      this.notifyAuthListeners();
      if (wasAuthenticated) {
        this.teardown(4003, 'client-signed-out');
      }
    }
  }

  public async setAuthToken(token?: string) {
    this.authToken = token;
    return this.authenticate();
  }

  public async sendPing() {
    const pingMsg = this.buildMessage({
      method: WSMessageTypes.ping,
      data: {
        clientTime: new Date().getTime(),
      },
    });
    const response = await this.makeSynchronousRequest<PingRequestData, PingResponseData>(
      pingMsg,
      PING_TIMEOUT,
    );
    if (response.data && response.data.valid) {
      const pangMsg = this.buildMessage({
        method: WSMessageTypes.pang,
        data: {
          clientTime: new Date().getTime(),
          pongUUID: response.data.pongUUID,
        },
      });
      const pangResponse = await this.makeSynchronousRequest<PangRequestData, PangResponseData>(
        pangMsg,
        PING_TIMEOUT,
      );
      if (pangResponse.data && pangResponse.data.valid) {
        const { clockOffset, latency } = pangResponse.data;
        const clockState: ClockInfo = {
          clockOffset,
          latency,
        };
        this.lastClockState = clockState;
        this.clockListeners.forEach((l) => l(clockState));
      } else {
        // don't think we can reach this?
        reportError('Error during pang', response.exception);
      }
    } else {
      // don't think we can reach this?
      reportError('Error during ping', response.exception);
    }
  }

  public startPing() {
    this.sendPing()
      .then(() => {
        this.pingIntervalId = setInterval(async () => {
          try {
            await this.sendPing();
          } catch (err) {
            if (err === 'timeout') {
              this.teardown(4002, 'ping-timed-out');
            } else {
              throw err;
            }
          }
        }, PING_INTERVAL);
      })
      .catch((err) => {
        if (err === 'timeout') {
          this.teardown(4002, 'ping-timed-out');
        } else {
          throw err;
        }
      });
  }

  public stopPing() {
    if (this.pingIntervalId) {
      clearTimeout(this.pingIntervalId);
    }
  }

  public registerClockListener(listener: ClockListener): DeRegisterFn {
    this.clockListeners.push(listener);
    listener(this.lastClockState);
    return () => {
      this.clockListeners.filter((l) => l !== listener);
    };
  }

  /*** Sync Requests ***/

  private getNewMessageId(): string {
    return uuidv4();
  }

  private buildMessage = (message: object, requestId: string = this.getNewMessageId()): any => {
    return {
      ...message,
      request_id: requestId,
    };
  };

  private sendAndWaitForResponse = (
    message: any,
    isResponse: IsResponseFn,
    timeout: number,
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line prefer-const
      let unsubscribe: DeRegisterFn, timeoutId: NodeJS.Timeout;
      function unsubscribeFromListening(): void {
        if (unsubscribe) {
          unsubscribe();
        }
      }
      // eslint-disable-next-line prefer-const
      timeoutId = setTimeout(() => {
        unsubscribeFromListening();
        reject('timeout');
      }, timeout);
      function onMessage(response: any): void {
        const msgData = JSON.parse(response);
        if (isResponse(msgData)) {
          unsubscribeFromListening();
          clearTimeout(timeoutId);
          resolve(msgData);
        }
      }
      unsubscribe = this.registerMessageListener(onMessage);
      this.send(JSON.stringify(message));
    });
  };

  makeSynchronousRequest = <RequestDataType, ResponseDataType>(
    message: Omit<ApiRequest<RequestDataType>, 'request_id'>,
    timeout?: number,
  ): Promise<ApiResponse<ResponseDataType>> => {
    const requestId = this.getNewMessageId();
    const messageToSend = this.buildMessage(message, requestId);
    return this.sendAndWaitForResponse(
      messageToSend,
      (message) => message.request_id === requestId,
      timeout || this.syncRequestTimeout,
    );
  };

  /*** API Calls ***/

  public sendPostRaceDataMessage(measure: RaceDataMeasure, value: number) {
    const msg: WSRequestMessages['postData'] = this.buildMessage({
      method: WSMessageTypes.postData,
      data: {
        measurements: [
          {
            field: measure,
            value,
          },
        ],
      },
    });
    this.sendJSON(msg);
  }

  /*** Message Listeners ***/

  public registerListenerForApiMessage<M extends WsAsyncMethod>(
    messageType: M,
    listener: (data: WSApiMessages[M]) => void,
  ) {
    const messageListener: MessageListener = (data) => {
      const obj = JSON.parse(data) as WsMessages[M];
      if (obj.action === messageType) {
        listener(obj);
      }
    };
    this.registerMessageListener(messageListener);
    return () => {
      this.deregisterMessageListener(messageListener);
    };
  }

  /*** Auth Listeners ***/
  private notifyAuthListeners() {
    this.authListeners.forEach((listener) => this.notifyAuthListener(listener));
  }

  private notifyAuthListener(listener: AuthListener) {
    listener(this.isAuthenticated);
  }

  public registerAuthListener(listener: AuthListener): DeRegisterFn {
    this.authListeners.push(listener);
    this.notifyAuthListener(listener);
    return () => this.deregisterAuthListener(listener);
  }

  public deregisterAuthListener(listener: AuthListener) {
    this.authListeners = this.authListeners.filter((l) => l !== listener);
  }
}
