import { useEffect, useRef, useState } from 'react';
import { useDaily, useLocalSessionId, useParticipantIds, useNetwork } from '@daily-co/daily-react';
import { DailyParticipant } from '@daily-co/daily-js';

export const useCallData = () => {
  const [callParticipants, setCallParticipants] = useState<DailyParticipant[]>([]);

  const callObject = useDaily();

  const callObjectRef = useRef(callObject);
  callObjectRef.current = callObject;

  const { quality, threshold } = useNetwork();
  const localSessionId = useLocalSessionId();
  const participantIds = useParticipantIds({ filter: 'remote' });

  useEffect(() => {
    if (callObjectRef.current && participantIds.length) {
      setCallParticipants(Object.values(callObjectRef.current.participants()));
    } else {
      setCallParticipants([]);
    }
  }, [callObject, participantIds]);

  return {
    callObjectRef,
    localSessionId,
    callParticipants,
    quality,
    threshold,
  };
};
