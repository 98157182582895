import { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  spacesActions,
  spacesSelectors,
  // tournamentsActions
} from '~/store/slices';
import { WSService } from '~/module/api/services';
import {
  transformRaceSpace,
  transformSpaceParticipant,
  transformSpaceParticipantState,
  // transformTournament,
} from './transforms';

const getSpaceIdFromRaceTopic = (topic: string): string => {
  return topic.replace(/^spaces\/|\/race$/g, '');
};

const getUserIdFromRaceObserverTopic = (topic: string): string => {
  return topic.replace(/^observer\//, '');
};

export const useListeners = () => {
  const dispatch = useDispatch();
  const unsubscribeFnsRef = useRef<Function[]>([]);

  const activeSpace = useSelector(spacesSelectors.getActiveSpace);
  const subscribedSpaceTopicsRef = useRef<string[]>([]);

  useEffect(() => {
    if (activeSpace) {
      subscribedSpaceTopicsRef.current = [`spaces/${activeSpace.id}/race`];
    } else {
      subscribedSpaceTopicsRef.current = [];
    }
  }, [activeSpace]);

  const startMessageListeners = useCallback(
    (ws: WSService) => {
      const unsubscribePublicSpaces = ws.registerListenerForApiMessage(
        'publicSpaces',
        (message) => {
          dispatch(
            spacesActions.onAvailableSpacesUpdated({
              spaces: message.data.spaceDetails.map(transformRaceSpace),
            }),
          );
        },
      );
      // const unsubscribePublicTournaments = ws.registerListenerForApiMessage(
      //   'publicTournaments',
      //   (message) => {
      //     console.log('publicTournaments', message);
      //     dispatch(
      //       tournamentsActions.onAvailableTournamentsUpdated({
      //         tournaments: message.data.tournamentDetails.map(transformTournament),
      //       }),
      //     );
      //   },
      // );

      const unsubscribeSpaceParticipants = ws.registerListenerForApiMessage(
        'listAllUsersInSpace',
        (message) => {
          dispatch(
            spacesActions.onSetSpaceParticipants({
              spaceId: getSpaceIdFromRaceTopic(message.topic),
              users: message.data.users.map(transformSpaceParticipant),
            }),
          );
        },
      );
      const unsubscribeSpaceObservers = ws.registerListenerForApiMessage(
        'listAllObserversInSpace',
        (message) => {
          dispatch(
            spacesActions.onSetSpaceObservers({
              spaceId: getSpaceIdFromRaceTopic(message.topic),
              users: message.data.users.map(transformSpaceParticipant),
            }),
          );
        },
      );
      const unsubscribeNewUserJoinedSpace = ws.registerListenerForApiMessage(
        'newUserJoinedSpace',
        (message) => {
          dispatch(
            spacesActions.onAddSpaceParticipant({
              spaceId: getSpaceIdFromRaceTopic(message.topic),
              user: transformSpaceParticipant(message.data.user.userDetails),
            }),
          );
        },
      );
      const unsubscribeUserLeftSpace = ws.registerListenerForApiMessage(
        'userLeftSpace',
        (message) => {
          dispatch(
            spacesActions.onRemoveSpaceParticipant({
              spaceId: getSpaceIdFromRaceTopic(message.topic),
              participantId: message.data.user.userUUID,
            }),
          );
        },
      );
      const unsubscribeSpaceUpdates = ws.registerListenerForApiMessage('stateUpdate', (message) => {
        if (subscribedSpaceTopicsRef.current.includes(message.topic)) {
          dispatch(
            spacesActions.onUpdateActiveSpaceParticipantState({
              id: getSpaceIdFromRaceTopic(message.topic),
              participantsState: Object.entries(message.data).reduce((state, entry) => {
                const [key, value] = entry;
                return {
                  ...state,
                  [key]: transformSpaceParticipantState(value),
                };
              }, {}),
            }),
          );
        }
      });
      const unsubscribeMeasureUpdate = ws.registerListenerForApiMessage(
        'measureUpdate',
        (message) => {
          const participantId = getUserIdFromRaceObserverTopic(message.topic);
          message.data.measurements.forEach(({ field, value }) => {
            dispatch(
              spacesActions.onUpdateParticipantMeasure({
                participantId,
                measure: field,
                value,
              }),
            );
          });
        },
      );
      const unsubscribeRiderPowerandHRUpdate = ws.registerListenerForApiMessage(
        'riderPowerAndHRUpdate',
        (message) => {
          if (subscribedSpaceTopicsRef.current.includes(message.topic)) {
            dispatch(spacesActions.onUpdateParticipantMeasures(message.data));
          }
        },
      );
      const unsubscribeRaceCountDown = ws.registerListenerForApiMessage(
        'raceCountDown',
        (message) => {
          if (subscribedSpaceTopicsRef.current.includes(message.topic)) {
            dispatch(
              spacesActions.onSetRaceStart({
                id: getSpaceIdFromRaceTopic(message.topic),
                state: 'countdown',
                raceStartTime: message.data.raceStartTime,
                assignedColours: message.data.assignedColours,
              }),
            );
          }
        },
      );
      const unsubscribeRaceStart = ws.registerListenerForApiMessage('raceStart', (message) => {
        if (subscribedSpaceTopicsRef.current.includes(message.topic)) {
          dispatch(
            spacesActions.onSetRaceStart({
              id: getSpaceIdFromRaceTopic(message.topic),
              state: 'inplay',
              raceStartTime: message.data.raceStartTime,
              assignedColours: message.data.assignedColours,
            }),
          );
        }
      });
      const unsubscribeRaceEnd = ws.registerListenerForApiMessage('raceEnd', (message) => {
        if (subscribedSpaceTopicsRef.current.includes(message.topic)) {
          dispatch(
            spacesActions.onSetRaceEnded({
              id: getSpaceIdFromRaceTopic(message.topic),
              state: 'finished',
              raceDuration: message.data.duration,
              riderSummary: message.data.riderSummary.map((s) => {
                return {
                  id: s.riderUUID,
                  rank: s.rank,
                  distanceDelta: s.distanceDelta,
                  timeDelta: s.timeDelta,
                  raceComplete: s.raceComplete,
                };
              }),
            }),
          );
        }
      });
      const unsubscribeRiderFinished = ws.registerListenerForApiMessage(
        'riderFinished',
        (message) => {
          if (subscribedSpaceTopicsRef.current.includes(message.topic)) {
            dispatch(
              spacesActions.onSetRiderFinished({
                raceId: getSpaceIdFromRaceTopic(message.topic),
                riderId: message.data.riderUUID,
                finishTime: message.data.finishTime,
              }),
            );
          }
        },
      );

      unsubscribeFnsRef.current.push(unsubscribePublicSpaces);
      // unsubscribeFnsRef.current.push(unsubscribePublicTournaments);
      unsubscribeFnsRef.current.push(unsubscribeSpaceParticipants);
      unsubscribeFnsRef.current.push(unsubscribeSpaceObservers);
      unsubscribeFnsRef.current.push(unsubscribeNewUserJoinedSpace);
      unsubscribeFnsRef.current.push(unsubscribeUserLeftSpace);
      unsubscribeFnsRef.current.push(unsubscribeSpaceUpdates);
      unsubscribeFnsRef.current.push(unsubscribeMeasureUpdate);
      unsubscribeFnsRef.current.push(unsubscribeRiderPowerandHRUpdate);
      unsubscribeFnsRef.current.push(unsubscribeRaceCountDown);
      unsubscribeFnsRef.current.push(unsubscribeRaceStart);
      unsubscribeFnsRef.current.push(unsubscribeRaceEnd);
      unsubscribeFnsRef.current.push(unsubscribeRiderFinished);
    },
    [dispatch, unsubscribeFnsRef],
  );

  const stopMessageListeners = useCallback(() => {
    unsubscribeFnsRef.current.forEach((fn) => {
      fn();
    });
    unsubscribeFnsRef.current = [];
  }, [unsubscribeFnsRef]);

  return {
    startMessageListeners,
    stopMessageListeners,
  };
};
