import { Box, Icon, Spinner } from '~/components/common';
import { useActiveSpaceAv } from '~/store/hooks';

import { useAv } from '../provider';

export const CommsChannelsControl = () => {
  const { isInActiveSpaceAv } = useActiveSpaceAv();
  const { appState } = useAv();

  return (
    <Box
      round="full"
      elevation="medium"
      pad="medium"
      background={isInActiveSpaceAv ? (appState < 0 ? 'status-error' : 'status-ok') : 'background'}
    >
      {appState === 0 && isInActiveSpaceAv ? (
        <Spinner />
      ) : (
        <Icon iconName="microphone" size="28px" />
      )}
    </Box>
  );
};
