import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appActions, appSelectors } from '../slices';

export const useDevSwitches = () => {
  const dispatch = useDispatch();

  const enableBob = useSelector(appSelectors.getIsBobEnabled);

  const onToggleBob = useCallback(() => {
    dispatch(appActions.onEnableBob(!enableBob));
  }, [dispatch, enableBob]);

  const bobWattage = useSelector(appSelectors.getBobWattage);

  const onSetBobWattage = useCallback(
    ({ power }: { power: number }) => {
      dispatch(appActions.onSetBobWattage({ power }));
    },
    [dispatch],
  );

  const enableThreePostProcessing = useSelector(appSelectors.getIsThreePostProcessingEnabled);

  const onToggleThreePostProcessing = useCallback(() => {
    dispatch(appActions.onEnableThreePostProcessing(!enableThreePostProcessing));
  }, [dispatch, enableThreePostProcessing]);

  const enableThreePerf = useSelector(appSelectors.getIsThreePerfEnabled);

  const onToggleThreePerf = useCallback(() => {
    dispatch(appActions.onEnableThreePerf(!enableThreePerf));
  }, [dispatch, enableThreePerf]);

  const enableAv = useSelector(appSelectors.getIsAvEnabled);

  const onToggleAv = useCallback(() => {
    dispatch(appActions.onEnableAv(!enableAv));
  }, [dispatch, enableAv]);

  return {
    enableBob,
    onToggleBob,
    bobWattage,
    onSetBobWattage,
    enableThreePostProcessing,
    onToggleThreePostProcessing,
    enableThreePerf,
    onToggleThreePerf,
    enableAv,
    onToggleAv,
  };
};
