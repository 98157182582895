import { CheckBox } from 'grommet';
import { useState } from 'react';
import { Box, Button, Icon, Modal, Text } from '~/components/common';

type JoinCommsPopupProps = {
  visible: boolean;
  title?: string;
  description?: string;
  confirmCta?: string;
  cancelCta?: string;
  onConfirm: (opts: { alwaysJoin: boolean }) => void;
  onCancel: (opts: { alwaysJoin: boolean }) => void;
  onRequestClose: () => void;
};

export const JoinCommsPopup = ({
  visible,
  title = 'Join race comms',
  description = 'Chat with others in the space. Requires video and microphone access.',
  confirmCta = 'Join call',
  cancelCta = 'No',
  onCancel,
  onConfirm,
  onRequestClose,
}: JoinCommsPopupProps) => {
  const [alwaysJoin, setAlwaysJoin] = useState(false);

  return (
    <Modal isVisible={visible} onRequestClose={onRequestClose} width="small">
      <Box align="center" gap="medium">
        <Icon iconName="microphone" size="large" />
        <Box align="center" gap="small">
          <Text size="xlarge" weight="lighter">
            {title}
          </Text>
          <Text textAlign="center">{description}</Text>
        </Box>
        <Box>
          <CheckBox
            label="Always join race comms (don't ask me again)"
            checked={alwaysJoin}
            onChange={(e) => setAlwaysJoin(e.target.checked)}
          />
        </Box>
        <Box direction="row" gap="medium">
          <Button label={cancelCta} size="large" onClick={() => onCancel({ alwaysJoin })} />
          <Button
            label={confirmCta}
            primary
            size="large"
            onClick={() => onConfirm({ alwaysJoin })}
          />
        </Box>
      </Box>
    </Modal>
  );
};
