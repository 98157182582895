import { useDispatch, useSelector } from 'react-redux';

import { spacesActions, spacesSelectors } from '../slices';
import { useCallback } from 'react';

export const useActiveSpaceAv = () => {
  const dispatch = useDispatch();

  const activeSpaceAv = useSelector(spacesSelectors.getActiveSpaceAv);
  const activeSpace = useSelector(spacesSelectors.getActiveSpace);
  const activeGroupSpace = useSelector(spacesSelectors.getActiveGroupSpace);
  const alwaysJoinActiveSpaceAv = useSelector(spacesSelectors.getAlwaysJoinActiveSpaceAv);

  const onSetAlwaysJoinActiveSpaceAv = useCallback(
    ({ alwaysJoin }: { alwaysJoin: boolean }) => {
      dispatch(spacesActions.onSetAlwaysJoinActiveSpaceAv({ alwaysJoin }));
    },
    [dispatch],
  );

  return {
    isInActiveSpaceAv: !!activeSpaceAv,
    isInRaceSpaceAv: !!activeSpace && !!activeSpaceAv && activeSpace?.id === activeSpaceAv?.id,
    isInGroupSpaceAv:
      !!activeGroupSpace && !!activeSpaceAv && activeGroupSpace?.id === activeSpaceAv?.id,
    alwaysJoinActiveSpaceAv,
    onSetAlwaysJoinActiveSpaceAv,
  };
};
