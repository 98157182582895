import { getMeasurementsAndRecords } from '~/components/data-viz/SepPowerRecords/utils';
import chrisUser from '~/components/data-viz/SepPowerRecords/story-data/chris-user.json';
import measurements from '~/components/data-viz/SepPowerRecords/story-data/measurements.json';
import { SepPowerRecords } from '~/components/data-viz/SepPowerRecords';

const RACE_ID = '1669653164479-novel-issue';

const [measures] = getMeasurementsAndRecords(RACE_ID, measurements, chrisUser['powerRecords'], [
  'wattage',
  'heartrate',
  'speed',
]);
export const PowerRecordsExample = () => {
  return <SepPowerRecords measures={measures} foundRecords={[]} height={200} />;
};
