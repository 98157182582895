import { FC } from 'react';

import { Box, Button, Dot, Text } from '~/components/common';
import { getOrdinal, msToTime, timeToClockString } from '~/module/utils';
import { useCallData } from '~/features/AudioVideo/hooks';
import { findCallParticipant } from '~/features/AudioVideo/AudioVideo.utils';
import { RaceSpace } from '~/store/slices';
import { AvControlsLocal } from '~/features/AudioVideo/components/AvControls';

import { RiderParticipantSummary } from '../../Race.types';
import { HudParticipantVideo } from './components';
import { AvailableSpaces } from '../AvailableSpaces';

export const RaceOver: FC<{
  observerUserId?: string;
  raceDuration: number;
  ridersSummary: RiderParticipantSummary[];
  nextUpRacesTitle: string;
  nextUpRaces: RaceSpace[];
  hasAv: boolean;
  onGoBackTitle: string;
  onGoBack: () => void;
  onNavToSpace: (spaceId: string) => void;
  onNavToObserveSpace: (spaceId: string) => void;
}> = ({
  raceDuration,
  ridersSummary,
  observerUserId,
  nextUpRacesTitle,
  nextUpRaces,
  hasAv,
  onGoBackTitle,
  onGoBack,
  onNavToSpace,
  onNavToObserveSpace,
}) => {
  const timeDuration = msToTime(raceDuration);
  const { callParticipants, localSessionId } = useCallData();

  return (
    <Box>
      <Box pad="large" align="center">
        <Text
          size="5xl"
          color="gold"
          weight="bolder"
          style={{
            textShadow: '3px 3px 1px #5f6100',
          }}
        >
          RACE FINISHED
        </Text>
      </Box>
      <Box direction="row">
        <Box flexNum={1} pad="xlarge" gap="large">
          <Box>
            {ridersSummary.map((r) => {
              const timeBehindLeader = msToTime(r.timeDelta);
              const isMe = r.id === observerUserId;
              const color = r.assignedColor ? `#${r.assignedColor}` : 'primary-text';
              return (
                <Box
                  key={r.id}
                  margin={{ bottom: 'small' }}
                  elevation={isMe ? 'small' : 'none'}
                  round
                  pad="small"
                >
                  <Box direction="row" align="center">
                    <Dot color={color} size={32} margin={{ right: 'small' }} />
                    <Text size="3xl">
                      {r.rank}
                      {getOrdinal(r.rank)}&nbsp;-&nbsp;
                    </Text>
                    <Text size="3xl">{r.displayName || '-'}</Text>
                  </Box>
                  {r.rank === 1 && (
                    <Text size="large">
                      Finish Time:{' '}
                      {timeToClockString(timeDuration, {
                        hours: timeDuration.hours > 0 ? true : false,
                        minutes: true,
                        seconds: true,
                        ms: true,
                      })}
                    </Text>
                  )}
                  {r.rank > 1 && (
                    <>
                      {!r.raceComplete ? (
                        <Text>DNF: {r.distanceDelta}m from finish</Text>
                      ) : (
                        <Text>
                          +{' '}
                          {timeToClockString(timeBehindLeader, {
                            minutes: true,
                            seconds: true,
                            ms: true,
                          })}
                        </Text>
                      )}
                    </>
                  )}
                </Box>
              );
            })}
          </Box>

          {nextUpRaces.length > 0 && (
            <Box>
              <Box>
                <Text tag="h3">{nextUpRacesTitle}</Text>
              </Box>
              <AvailableSpaces
                availableSpaces={nextUpRaces}
                userDistanceScale={1}
                onNavToSpace={onNavToSpace}
                onNavToObserveSpace={onNavToObserveSpace}
              />
            </Box>
          )}
          <Box pad="medium" align="center">
            <Button onClick={onGoBack} size="large" label={onGoBackTitle} />
          </Box>
        </Box>

        {hasAv && (
          <Box justify="start" gap="small">
            {ridersSummary.map((r) => {
              const isMe = r.id === observerUserId;
              const callParticipant =
                (isMe ? localSessionId : findCallParticipant(callParticipants, r.id)) ?? null;
              return (
                <Box key={r.id} pad="small">
                  <HudParticipantVideo
                    key={r.id}
                    hasAv={!!callParticipant}
                    id={callParticipant || ''}
                    videoEnabled
                    mirrorVideo={isMe}
                    assignedColour={r.assignedColor}
                  />
                  {isMe && <AvControlsLocal />}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
