import { mean } from 'lodash';
import { Data } from 'plotly.js';
import { FC, useMemo } from 'react';
import Plot from 'react-plotly.js';

import { FoundRecords, HistoryDatum, Layout, MeasureData, Trace } from '../data-viz.types';
import { getLongStringDuration, rawNumberToColour } from '../utils';

export const SepPowerRecords: FC<{
  measures: MeasureData;
  foundRecords: FoundRecords[];
  height?: number;
}> = ({ height = 250, measures, foundRecords }) => {
  function getValues(measures: HistoryDatum[]): [boolean, Date[], number[]] {
    if (measures.length === 0) {
      return [false, [], []];
    } else {
      const xValues = measures.map((d) => d.x);
      const yValues = measures.map((d) => d.y);
      return [true, xValues, yValues];
    }
  }
  const [hasWattage, xWattage, yWattage] = useMemo(
    () => getValues(measures['wattage'] || []),
    [measures],
  );
  const [hasHR, xHR, yHR] = useMemo(() => getValues(measures['heartrate'] || []), [measures]);
  const [hasCadence, xCadence, yCadence] = useMemo(
    () => getValues(measures['cadence'] || []),
    [measures],
  );
  const [hasSpeed, xSpeed, ySpeed] = useMemo(() => getValues(measures['speed'] || []), [measures]);

  const maxPower = Math.max(...yWattage);
  const halfMeanPower = mean(yWattage) / 2;
  const minHR = Math.min(...yHR);
  const maxHR = Math.max(...yHR);
  const minSpeed = Math.min(...ySpeed);
  const maxSpeed = Math.max(...ySpeed);
  const minCadence = Math.min(...yCadence);
  const maxCadence = Math.max(...yCadence);

  const hasPowerRecords = foundRecords.length > 0;

  const alpha = 0.2;
  const config: {
    [key: string]: {
      lineColour: string;
      fillColour: string;
      axisText: string;
      minRange: number;
      maxRange: number;
    };
  } = {
    wattage: {
      lineColour: '#00a1e4',
      fillColour: 'rgba(0, 161, 228, ' + alpha + ')',
      axisText: 'Power (W)',
      minRange: 0,
      maxRange: maxPower * 1.01,
    },
    heartrate: {
      lineColour: '#ff10f0',
      fillColour: 'rgba(255, 16, 240, ' + alpha + ')',
      axisText: 'Heartrate (BPM)',
      minRange: minHR * 0.99,
      maxRange: maxHR * 1.01,
    },
    speed: {
      lineColour: '#f6ae2d',
      fillColour: 'rgba(246, 174, 45, ' + alpha + ')',
      axisText: 'Speed (Km/h)',
      minRange: minSpeed * 0.99,
      maxRange: maxSpeed * 1.01,
    },
    cadence: {
      lineColour: '#36f1cd',
      fillColour: 'rgba(54, 241, 205, ' + alpha + ')',
      axisText: 'Cadence (RPM)',
      minRange: minCadence * 0.99,
      maxRange: maxCadence * 1.01,
    },
  };

  const fieldOrder = ['wattage', 'heartrate', 'cadence', 'speed'];
  const fieldBooleans = [hasWattage, hasHR, hasCadence, hasSpeed];
  const fieldXValues = [xWattage, xHR, xCadence, xSpeed];
  const fieldYValues = [yWattage, yHR, yCadence, ySpeed];

  let traces: Trace[] = [];
  let nextYIndex = 1;

  fieldOrder.forEach((field, index) => {
    if (fieldBooleans[index]) {
      // we have data, we can show a chart
      const trace: Trace = {
        x: fieldXValues[index],
        y: fieldYValues[index],
        mode: 'lines',
        type: 'scatter',
        fill: 'tozeroy',
        fillcolor: config[field].fillColour,
        hoverinfo: 'x+y',
        line: {
          shape: 'spline',
          color: config[field].lineColour,
        },
      };
      if (nextYIndex > 1) {
        trace['yaxis'] = 'y' + nextYIndex;
      }
      traces.push(trace);
      nextYIndex += 1;
      if (hasPowerRecords && field === 'wattage') {
        // add a the power records over wattage, so take a copy of wattage but in white and with opacity
        const trace: Trace = {
          x: fieldXValues[index],
          y: fieldYValues[index],
          mode: 'lines',
          type: 'scatter',
          fill: 'tozeroy',
          fillcolor: 'rgba(255,255, 255, 0.2)',
          hoverinfo: 'none',
          line: {
            shape: 'spline',
            color: 'rgba(255,255, 255, 0.6)',
          },
        };
        if (nextYIndex > 1) {
          trace['yaxis'] = 'y' + nextYIndex;
        }
        traces.push(trace);
        nextYIndex += 1;
      }
    }
  });

  const plotCount = nextYIndex - 1;

  // need 0.07 between plots
  const spaceNeeded = 0.07 * (plotCount - 1);
  const remainingSpace = 1 - spaceNeeded;
  const perPlotAllocation = remainingSpace / plotCount;

  const layout: Layout = {
    showlegend: false,
    paper_bgcolor: '#000',
    plot_bgcolor: '#000',
    font: {
      color: '#fff',
    },
    xaxis: {
      color: '#fff',
      domain: [0.0, 1.0],
      tickformat: '%M:%S',
    },
    // width: 900,
    height: height * plotCount,
  };

  let plotsAdded = 0;

  function getDomain(thisPlotNumber: number, spacing: number): [number, number] {
    const sumSpaces = 0.07 * (thisPlotNumber - 1);
    const top = 1 - sumSpaces - spacing * (thisPlotNumber - 1);
    const bottom = top - spacing;
    return [bottom, top];
  }

  function addAxisToLayout(propName: string, layout: Layout, thisLayout: any) {
    if (propName === 'yaxis') {
      layout['yaxis'] = thisLayout;
    }
    if (propName === 'yaxis2') {
      layout['yaxis2'] = thisLayout;
    }
    if (propName === 'yaxis3') {
      layout['yaxis3'] = thisLayout;
    }
    if (propName === 'yaxis4') {
      layout['yaxis4'] = thisLayout;
    }
    if (propName === 'yaxis5') {
      layout['yaxis5'] = thisLayout;
    }
  }

  fieldOrder.forEach((field, index) => {
    if (fieldBooleans[index]) {
      // we have data, we can add the chart
      plotsAdded += 1;
      const propName = 'yaxis' + String(plotsAdded > 1 ? plotsAdded : '');
      const thisLayout = {
        color: '#fff',
        domain: getDomain(plotsAdded, perPlotAllocation),
        range: [config[field].minRange, config[field].maxRange],
        fixedrange: true,
        title: {
          text: config[field].axisText,
        },
      };
      addAxisToLayout(propName, layout, thisLayout);
      if (hasPowerRecords && field === 'wattage') {
        plotsAdded += 1;
        const propName = 'yaxis' + String(plotsAdded > 1 ? plotsAdded : '');
        const thisLayout = {
          color: '#fff',
          domain: getDomain(plotsAdded, perPlotAllocation),
          range: [config[field].minRange, config[field].maxRange],
          fixedrange: true,
          title: {
            text: 'Power Records',
          },
        };
        addAxisToLayout(propName, layout, thisLayout);
      }
    }
  });

  // now add the Traces for the horizontal lines from power records (if any exist.)
  // need startTime, duration, value

  foundRecords = foundRecords.sort((a, b) => (a.value > b.value ? 1 : -1));
  // ordering ensures we can access all plots with the tool tip hover

  foundRecords.forEach((record) => {
    const trace: Trace = {
      x: [
        new Date(record.startTime),
        new Date(record.startTime),
        new Date(record.startTime + record.duration * 1000),
        new Date(record.startTime + record.duration * 1000),
      ],
      y: [0, record.value, record.value, 0, 0],
      yaxis: 'y2',
      mode: 'lines',
      type: 'scatter',
      hoveron: 'fills',
      hoverinfo: 'text',
      text: String(Math.round(record.value)) + 'W for ' + getLongStringDuration(record.duration),
      fillcolor: rawNumberToColour(record.value, 0.4, maxPower, halfMeanPower),
      line: {
        color: rawNumberToColour(record.value, 0.6, maxPower, halfMeanPower),
      },
      fill: 'toself',
    };
    traces.push(trace);
  });

  return (
    <Plot
      data={traces as Data[]}
      layout={layout}
      config={{ responsive: true }}
      style={{ width: '100%' }}
    />
  );
};
