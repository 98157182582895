import { FC } from 'react';
import { isToday } from 'date-fns';

import {
  TournamentComplete,
  TournamentPreStart,
  TournamentStartingSoon,
  TournamentLiveRound,
  TournamentPendingUpdate,
} from './components';
import { TournamentRoundInfo, TournamentRaceRiderRank, RaceStatus } from '~/store/slices';
import { NotConnectedWarning } from '~/features/Devices';
import { Box } from '~/components/common';

import { TournamentItemState } from '../../Tournaments.types';
import { useTournament } from './useTournament';

/**
 *
 * BACKLOG:
 * - must be online by (keep this short on the backend, and make sure people are online)
 * - registration closes time window
 * - audio video chat
 */

type TournamentNavProps = {
  tournamentId: string;
  onNavToObserveRace: (spaceId: string) => void;
  onOpenDevicesModal: () => void;
};

type TournamentProps = {
  userId?: string;
  isFetchingTournamentEntries: boolean;
  tournament: TournamentItemState | null;
  isJoining: boolean;
  hasJoined: boolean;
  estimatedNumberOfRounds: number;
  onJoinTournament: () => void;
  isLeaving: boolean;
  onLeaveTournament: () => void;
  riderRanks: TournamentRaceRiderRank[];
  rounds: TournamentRoundInfo[];
  previousRounds: TournamentRoundInfo[];
  currentRound: TournamentRoundInfo | null;
  isStillInTournament: boolean;
  currentRaceId?: string | null;
  hasJoinedRace: boolean;
  raceState: RaceStatus | null;
  raceStartTime?: number;
  isControllableConnected: boolean;
};

function isLiveRound(state: string) {
  return (
    state.indexOf('completeRound') === 0 ||
    state.indexOf('racingRound') === 0 ||
    state.indexOf('pendingRound') === 0
  );
}

export const Tournament: FC<TournamentProps & TournamentNavProps> = ({
  userId,
  isFetchingTournamentEntries,
  tournament,
  isJoining,
  hasJoined,
  estimatedNumberOfRounds,
  onJoinTournament,
  isLeaving,
  onLeaveTournament,
  riderRanks,
  rounds,
  currentRound,
  previousRounds,
  isStillInTournament,
  currentRaceId,
  hasJoinedRace,
  onNavToObserveRace,
  raceState,
  raceStartTime,
  isControllableConnected,
  onOpenDevicesModal,
}) => {
  if (!tournament) {
    return <TournamentPendingUpdate />;
  }

  /**
   * `complete`
   */
  if (tournament.state === 'complete') {
    return (
      <TournamentComplete
        userId={userId}
        tournament={tournament}
        riderRanks={riderRanks}
        rounds={rounds}
      />
    );
  }

  const isInLiveRound = isLiveRound(tournament.state);
  const isInPreEventCountdown =
    tournament.state === 'pre-event' &&
    !tournament.details.registrationOpen &&
    hasJoined &&
    !isInLiveRound;
  const isInPreEvent = tournament.state === 'pre-event' && !isInPreEventCountdown;
  const today = isToday(new Date(tournament.details.startTime));

  const showConnectTrainerWarning =
    today && hasJoined && isStillInTournament && !isControllableConnected;

  return (
    <Box style={{ position: 'relative' }}>
      {isInLiveRound && (
        /**
         * `pendingRound1` - the draw has been made, races have been allocated, waiting for races to start
         * `racingRound1` - any active race in the round is in progress
         * `completeRound1` - no more active races in this round, waiting for next round or move to complete. Probably only here very briefly.
         */
        <>
          {currentRound ? (
            <TournamentLiveRound
              userId={userId}
              tournament={tournament}
              round={currentRound}
              previousRounds={previousRounds}
              hasJoined={hasJoined}
              hasJoinedRace={hasJoinedRace}
              currentRaceId={currentRaceId}
              raceState={raceState}
              raceStartTime={raceStartTime}
              onNavToObserveRace={onNavToObserveRace}
            />
          ) : (
            <TournamentPendingUpdate />
          )}
        </>
      )}

      {isInPreEventCountdown && (
        /**
         * `pre-event` - starting soon
         * Registration closed and user has joined - waiting for tournament to start
         */
        <TournamentStartingSoon tournament={tournament} />
      )}

      {isInPreEvent && (
        /**
         * `pre-event` - registration open
         */
        <Box style={{ paddingTop: showConnectTrainerWarning ? 80 : 0 }}>
          <TournamentPreStart
            tournament={tournament}
            isJoining={isJoining}
            hasJoined={hasJoined}
            canJoin={!isFetchingTournamentEntries}
            isLeaving={isLeaving}
            canLeave={!isFetchingTournamentEntries}
            estimatedNumberOfRounds={estimatedNumberOfRounds}
            onJoinTournament={onJoinTournament}
            onLeaveTournament={onLeaveTournament}
          />
        </Box>
      )}

      {showConnectTrainerWarning && (
        <Box style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 10 }}>
          <NotConnectedWarning
            deviceIcon="bike"
            deviceLabel="Indoor Trainer"
            onConnect={onOpenDevicesModal}
          />
        </Box>
      )}
    </Box>
  );
};

export default function TournamentConnected(navProps: TournamentNavProps) {
  const props = useTournament(navProps);
  return <Tournament {...navProps} {...props} />;
}
