import {
  signOut,
  signInWithEmailAndPassword,
  UserCredential,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  User,
  getIdToken,
} from 'firebase/auth';
import { auth } from './InitFirebase';

let firebaseUser: User | null = null;

export const logout = () => {
  signOut(auth);
};

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<UserCredential> => {
  const result = await signInWithEmailAndPassword(auth, email, password);
  return result;
};

export const signUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<UserCredential> => {
  const result = await createUserWithEmailAndPassword(auth, email, password);
  return result;
};

export const requestPasswordReset = async ({ email }: { email: string }) => {
  await sendPasswordResetEmail(auth, email);
};

export const setFirebaseUser = (user: User | null) => {
  firebaseUser = user;
};

export const getFreshIdToken = async () => {
  if (firebaseUser) {
    const token = await getIdToken(firebaseUser, true);
    return token;
  }
};
