import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';

import { Box, Tag, Text } from '~/components/common';
import { TournamentRoundInfo } from '~/store/slices/tournamentsSlice.types';

import { TournamentSpaceDetails } from './TournamentSpaceDetails';
import { TournamentRoundLeaderboard } from './TournamentRoundLeaderboard';

export type TournamentRoundProps = {
  userId?: string;
  round: TournamentRoundInfo;
  onNavToObserveRace?: (spaceId: string) => void;
};

export const TournamentRound = ({ round, userId, onNavToObserveRace }: TournamentRoundProps) => {
  const spaces = useMemo(
    () => _orderBy(Object.values(round.spaces), ['spaceUUID'], ['asc']),
    [round.spaces],
  );
  const { isFinal } = round;
  const qualifiersPerRace = round.raceWinners;
  return (
    <Box gap="medium">
      <Box
        background={round.state === 'complete' ? 'background-front' : 'focus'}
        round="xsmall"
        pad="small"
      >
        <Box direction="row" justify="between" align="center" gap="medium">
          <Text weight="bold" uppercase>
            {isFinal ? 'Final' : `Round ${round.roundNumber}`}
          </Text>
          <Tag size="small" value={round.state} />
        </Box>
      </Box>

      {!isFinal && (
        <Box direction="row" align="center" justify="start" gap="small">
          <Tag
            size="small"
            value={`${qualifiersPerRace} ${
              qualifiersPerRace === 1 ? 'winner' : 'winners'
            } from each race go through`}
          />
          {round.fastestFinishers > 0 && (
            <Tag
              size="small"
              value={`${round.fastestFinishers} fastest ${
                round.fastestFinishers === 1 ? 'finisher' : 'finishers'
              } from the round to go through`}
            />
          )}
        </Box>
      )}

      {spaces.length > 0 && (
        <Box direction="row" gap="large" wrap>
          {spaces.map((space) => {
            return (
              <Box key={space.spaceUUID} margin={{ bottom: 'medium' }}>
                <TournamentSpaceDetails
                  userId={userId}
                  space={space}
                  onNavToObserveRace={onNavToObserveRace}
                />
              </Box>
            );
          })}
        </Box>
      )}

      {round.state === 'complete' && (
        <Box width="large">
          <TournamentRoundLeaderboard
            userId={userId}
            roundResults={round.roundResults}
            isFinal={round.isFinal}
          />
        </Box>
      )}
    </Box>
  );
};
