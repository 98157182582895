import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _throttle from 'lodash/throttle';

function getNowMs() {
  return Number(new Date());
}

const activityCalbackThrottle = 500;
const tickInterval = 1000;

type Options = {
  inActiveTimeThreshold: number;
  inActiveWindowThreshold: number;
  useUserActiveCheck: boolean;
};
const defaultOptions: Options = {
  inActiveTimeThreshold: 10 * 60 * 1000,
  inActiveWindowThreshold: 5 * 60 * 1000, // 5 mins of racing
  useUserActiveCheck: false, // Screen may be inactive for long periods of time, during racing
};

export const useIsUserActive = (opts: Partial<Options> = {}) => {
  const [tick, setTick] = useState(getNowMs());
  const [isScreenVisible, setIsScreenVisible] = useState(true);
  const [lastActiveTime, setLastActiveTime] = useState(getNowMs());
  const options = useMemo(
    () => ({
      ...defaultOptions,
      ...opts,
    }),
    [opts],
  );

  const screenVisibilityTimeoutIdRef = useRef<NodeJS.Timeout>();

  const updateLastActiveTime = useCallback(() => {
    setLastActiveTime(getNowMs());
    // screen must be visible if user is active
    setIsScreenVisible(true);
  }, [setLastActiveTime, setIsScreenVisible]);

  const onVisibilityChange = useCallback(() => {
    if (document.hidden) {
      screenVisibilityTimeoutIdRef.current = setTimeout(() => {
        if (document.hidden) {
          setIsScreenVisible(false);
        }
      }, options.inActiveWindowThreshold);
    } else {
      clearTimeout(screenVisibilityTimeoutIdRef.current);
      setIsScreenVisible(true);
      updateLastActiveTime();
    }
  }, [options, updateLastActiveTime]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateLastActiveTimeThrottled = useCallback(
    _throttle(() => updateLastActiveTime(), activityCalbackThrottle),
    [updateLastActiveTime],
  );

  useEffect(() => {
    if (options.useUserActiveCheck) {
      document.body.addEventListener('keypress', updateLastActiveTimeThrottled);
      document.body.addEventListener('click', updateLastActiveTimeThrottled);
      document.addEventListener('mousemove', updateLastActiveTimeThrottled);
      return () => {
        document.body.removeEventListener('keypress', updateLastActiveTimeThrottled);
        document.body.removeEventListener('click', updateLastActiveTimeThrottled);
        document.removeEventListener('mousemove', updateLastActiveTimeThrottled);
      };
    }
  }, [updateLastActiveTimeThrottled, options]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onVisibilityChange]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTick(getNowMs());
    }, tickInterval);
    return () => clearInterval(intervalId);
  }, [setTick]);

  const isActive = useMemo(() => {
    const isWithinThreshold = options.useUserActiveCheck
      ? lastActiveTime + options.inActiveTimeThreshold > tick
      : true;
    if (isScreenVisible && isWithinThreshold) {
      return true;
    }
    return false;
  }, [lastActiveTime, tick, isScreenVisible, options]);

  return isActive;
};
