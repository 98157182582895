import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { spacesActions, spacesSelectors } from '~/store/slices';

import { useGroupSpaceActions } from './useGroupSpaceActions';

const SPACE_UUID = 'clean-cap';

export const useCritzGroupSpace = () => {
  const dispatch = useDispatch();

  const { onJoinSpace, onLeaveSpace } = useGroupSpaceActions(SPACE_UUID);

  const activeGroupSpace = useSelector(spacesSelectors.getActiveGroupSpace);
  const spaceParticipants = useSelector(spacesSelectors.getSpaceParticipants);

  const isInSpace = activeGroupSpace?.id === SPACE_UUID;

  const onJoinGroup = useCallback(
    async (opts: { isInAvCall: boolean }) => {
      await onJoinSpace();
      dispatch(
        spacesActions.onSetActiveGroupSpace({ id: SPACE_UUID, isInAvCall: opts.isInAvCall }),
      );
    },
    [onJoinSpace, dispatch],
  );

  const onLeaveGroup = useCallback(async () => {
    await onLeaveSpace();
    dispatch(spacesActions.onSetActiveGroupSpace(null));
  }, [onLeaveSpace, dispatch]);

  const participants = useMemo(() => {
    return Object.values(spaceParticipants[SPACE_UUID]?.participants ?? {});
  }, [spaceParticipants]);

  return {
    isInSpace,
    participants,
    onJoinGroup,
    onLeaveGroup,
  };
};
