import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Spinner, Text } from '~/components/common';
import { useWsApi, useWsResponseStatus } from '~/module/api';
import { appSelectors, spacesSelectors } from '~/store/slices';

type ConnectivityWarningProps = {
  isSocketConnected: boolean;
  isServerResponsive: boolean;
};

export const ConnectivityWarning: FC<ConnectivityWarningProps> = ({
  isSocketConnected,
  isServerResponsive,
}) => {
  const hasIssues = !isSocketConnected || !isServerResponsive;
  return (
    <Box
      style={{
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.4)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      direction="column"
      align="center"
      justify="center"
    >
      <Box
        align="center"
        background={hasIssues ? 'background-front' : 'status-ok'}
        width="large"
        round
        pad="medium"
      >
        {hasIssues ? (
          <Box gap="small" align="center">
            <Text textAlign="center" size="xlarge" weight="lighter">
              Sorry, we're having trouble connecting to the server
            </Text>
            {isSocketConnected ? (
              <Text textAlign="center">Connected, just waiting for server to respond...</Text>
            ) : (
              <Text textAlign="center">Reconnecting now...</Text>
            )}
            <Spinner />
          </Box>
        ) : (
          <Text textAlign="center" size="xlarge">
            Connected!
          </Text>
        )}
      </Box>
    </Box>
  );
};

export const ConnectivityWarningConnected = ({
  responseThreshold,
}: {
  responseThreshold: number;
}) => {
  const ws = useWsApi();

  const isSocketConnected = useSelector(appSelectors.getIsSocketConnected);

  const isWsHighlyResponsive = useWsResponseStatus(ws, {
    timeSinceLastMessageThreshold: responseThreshold,
  });

  const hasIssues = !isSocketConnected || !isWsHighlyResponsive;
  const [showConnectionIssues, setShowConnectionIssues] = useState(hasIssues);

  useEffect(() => {
    if (hasIssues) {
      setShowConnectionIssues(true);
    } else {
      const timeoutId = setTimeout(() => {
        setShowConnectionIssues(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [hasIssues]);

  if (showConnectionIssues) {
    return (
      <ConnectivityWarning
        isSocketConnected={isSocketConnected}
        isServerResponsive={isWsHighlyResponsive}
      />
    );
  }

  return null;
};
