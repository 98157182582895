import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RaceStatus, SpaceKind, RaceDataMeasure } from '~/module/api/api.types';
import { reportError } from '~/module/logging';

import { RootState } from '../rootReducer';
import { isLogoutAction } from './userSlice';

export { RaceStatus, SpaceKind };

export type RaceSpaceDetails = {
  state: RaceStatus;
  distance: number;
  duration: number;
  fieldSize: number;
  raceStart?: number;
  useDistanceScale: boolean;
};

export type BasicSpaceDetails = {};

export type RaceSpace = {
  id: string;
  spaceKind: 'race';
  currentParticipantCount: number;
  details: RaceSpaceDetails;
};

export type BasicSpace = {
  id: string;
  spaceKind: 'basic';
  currentParticipantCount: number;
  details: BasicSpaceDetails;
};

export type Space = RaceSpace | BasicSpace;

export type SpaceParticipant = {
  id: string;
  displayName: string;
  userName: string;
  weight: number;
  distanceScale: number; // to scale speeds and distances in-race
  riderColour?: string;
};

export type SpaceParticipantState = {
  lastUpdated: number;
  velocity: number;
  acceleration: number;
  percentageComplete: number;
  draftInfo: {
    efficiency: number;
    savingWatts: number;
  };
};

export type SpaceParticipantMeasures = {
  [key: string]: {
    wattage: number;
    heartRate: number;
    cadence: number;
  };
};

export type SpaceParticipantsState = { [key: string]: SpaceParticipantState };

export type SpaceParticipants = { [key: string]: SpaceParticipant };

export type RiderSummary = {
  id: string;
  rank: number;
  distanceDelta: number;
  timeDelta: number;
  raceComplete: boolean;
};

export type RiderFinishedSummary = {
  id: string;
  finishTime: number;
};

export type ActiveRaceSpace = {
  id: string;
  details: RaceSpaceDetails;
  raceStartTime?: number; // races don't have a scheduled start time, they start when filled
  ridersFinishedSummary: Record<string, RiderFinishedSummary>;
  raceEndRiderSummary: RiderSummary[];
  raceDuration: number;
  participantsState: SpaceParticipantsState;
  participantMeasures: SpaceParticipantMeasures;
  observingUserId?: string;
};

export type SpacesState = {
  /* clockOffset: Difference between server and client clock time */
  clockOffset: number;
  latency: number;
  availableSpaces: RaceSpace[];
  spaceParticipants: Record<
    string,
    {
      participants: Record<string, SpaceParticipant>;
      observers: Record<string, SpaceParticipant>;
    }
  >;
  activeSpaceAv: { id: string; spaceKind: SpaceKind } | null;
  activeSpace: ActiveRaceSpace | null;
  activeGroupSpace: { id: string } | null;
  sendAudio: boolean;
  sendVideo: boolean;
  alwaysJoinActiveSpaceAv: boolean;
};

const initialSpacesState: SpacesState = {
  clockOffset: 0,
  latency: 0,
  availableSpaces: [],
  spaceParticipants: {},
  activeSpaceAv: null,
  activeSpace: null,
  activeGroupSpace: null,
  sendAudio: true,
  sendVideo: false,
  alwaysJoinActiveSpaceAv: false,
};

export const spacesSlice = createSlice({
  name: 'spaces',
  initialState: initialSpacesState,
  reducers: {
    onAvailableSpacesUpdated(state, action: PayloadAction<{ spaces: RaceSpace[] }>) {
      state.availableSpaces = action.payload.spaces;
    },
    onSetActiveSpace(
      state,
      action: PayloadAction<{
        id: string;
        isInAvCall?: boolean;
        details: RaceSpaceDetails;
        observingUserId?: string;
      }>,
    ) {
      const { id, isInAvCall } = action.payload;
      state.activeSpace = {
        id: action.payload.id,
        details: action.payload.details,
        participantsState: {},
        participantMeasures: {},
        raceStartTime: action.payload.details.raceStart,
        observingUserId: action.payload.observingUserId,
        // todo - add race state from details
        raceEndRiderSummary: [],
        ridersFinishedSummary: {},
        raceDuration: 0,
      };
      if (isInAvCall) {
        state.activeSpaceAv = { id, spaceKind: 'race' };
      }
    },
    onSetActiveGroupSpace(
      state,
      action: PayloadAction<{ id: string; isInAvCall?: boolean } | null>,
    ) {
      const existingGroupSpaceId = state.activeGroupSpace?.id;
      const existingSpaceAvId = state.activeSpaceAv?.id;

      state.activeGroupSpace = action.payload ? { id: action.payload.id } : null;
      if (action.payload?.isInAvCall) {
        state.activeSpaceAv = { id: action.payload.id, spaceKind: 'basic' };
      }
      if (existingGroupSpaceId && !action.payload && existingGroupSpaceId === existingSpaceAvId) {
        state.activeSpaceAv = null;
      }
    },
    onSetActiveSpaceAv(state, action: PayloadAction<{ id: string; spaceKind: SpaceKind } | null>) {
      const space = action.payload;
      state.activeSpaceAv = space ? { id: space.id, spaceKind: space.spaceKind } : null;
    },
    onSetObservingUserId(state, action: PayloadAction<{ spaceId: string; observeUserId: string }>) {
      if (state.activeSpace?.id === action.payload.spaceId) {
        state.activeSpace.observingUserId = action.payload.observeUserId;
      }
    },
    onSetSpaceParticipants(
      state,
      action: PayloadAction<{ spaceId: string; users: SpaceParticipant[] }>,
    ) {
      const { spaceId, users } = action.payload;
      if (!state.spaceParticipants[spaceId]) {
        state.spaceParticipants[spaceId] = {
          participants: {},
          observers: {},
        };
      }
      users.forEach((user) => {
        state.spaceParticipants[spaceId].participants[user.id] = user;
      });
    },
    onSetSpaceObservers(
      state,
      action: PayloadAction<{ spaceId: string; users: SpaceParticipant[] }>,
    ) {
      const { spaceId, users } = action.payload;
      if (!state.spaceParticipants[spaceId]) {
        state.spaceParticipants[spaceId] = {
          participants: {},
          observers: {},
        };
      }
      users.forEach((user) => {
        state.spaceParticipants[spaceId].observers[user.id] = user;
      });
    },
    onLeaveSpace(state, action: PayloadAction<{ spaceId: string; userId: string }>) {
      const { spaceId, userId } = action.payload;
      if (state.spaceParticipants[spaceId] && state.spaceParticipants[spaceId].participants) {
        delete state.spaceParticipants[spaceId].participants[userId];
      }
      if (state.activeSpaceAv?.id === spaceId) {
        state.activeSpaceAv = null;
      }
      state.activeSpace = null;
    },
    onAddSpaceParticipant(
      state,
      action: PayloadAction<{ spaceId: string; user: SpaceParticipant }>,
    ) {
      const { spaceId, user } = action.payload;
      if (!state.spaceParticipants[spaceId]) {
        state.spaceParticipants[spaceId] = {
          participants: {},
          observers: {},
        };
      }
      state.spaceParticipants[spaceId].participants[user.id] = user;
    },
    onRemoveSpaceParticipant(
      state,
      action: PayloadAction<{ spaceId: string; participantId: string }>,
    ) {
      const { spaceId, participantId } = action.payload;
      if (state.spaceParticipants[spaceId] && state.spaceParticipants[spaceId].participants) {
        delete state.spaceParticipants[spaceId].participants[participantId];
      }
    },
    onUpdateActiveSpaceParticipantState(
      state,
      action: PayloadAction<{ id: string; participantsState: SpaceParticipantsState }>,
    ) {
      const { id, participantsState } = action.payload;
      if (state.activeSpace && state.activeSpace.id === id) {
        state.activeSpace.participantsState = participantsState;
      }
    },
    onUpdateParticipantMeasure(
      state,
      action: PayloadAction<{ participantId: string; measure: RaceDataMeasure; value: number }>,
    ) {
      const { participantId, measure, value } = action.payload;
      if (state.activeSpace) {
        // TODO: only update if participants are in the space
        if (!state.activeSpace.participantMeasures) {
          state.activeSpace.participantMeasures = {};
        }
        state.activeSpace.participantMeasures[participantId] = {
          ...state.activeSpace.participantMeasures[participantId],
          [measure]: value,
        };
      }
    },
    onUpdateParticipantMeasures(
      state,
      action: PayloadAction<{ [key: string]: { heartrate?: number; wattage?: number } }>,
    ) {
      const participantMeasures = action.payload;
      if (state.activeSpace) {
        if (!state.activeSpace.participantMeasures) {
          state.activeSpace.participantMeasures = {};
        }
        Object.entries(participantMeasures).forEach(([userId, values]) => {
          if (!state.activeSpace!.participantMeasures[userId]) {
            state.activeSpace!.participantMeasures[userId] = {
              heartRate: 0,
              cadence: 0,
              wattage: 0,
            };
          }
          if (typeof values.heartrate === 'number') {
            state.activeSpace!.participantMeasures[userId].heartRate = values.heartrate;
          }
          if (typeof values.wattage === 'number') {
            state.activeSpace!.participantMeasures[userId].wattage = values.wattage;
          }
        });
      }
    },
    onSetRaceStart(
      state,
      action: PayloadAction<{
        id: string;
        state: RaceStatus;
        raceStartTime: number;
        assignedColours?: Record<string, string>;
      }>,
    ) {
      const { id, raceStartTime, assignedColours } = action.payload;
      if (state.activeSpace && state.activeSpace.id === id) {
        state.activeSpace.details.state = action.payload.state;
        state.activeSpace.raceStartTime = raceStartTime;
      }
      if (assignedColours) {
        for (const [riderId, colour] of Object.entries(assignedColours)) {
          if (state.spaceParticipants[id] && state.spaceParticipants[id].participants[riderId]) {
            state.spaceParticipants[id].participants[riderId].riderColour = colour;
          } else {
            reportError(`Participant not found in client state at ${action.payload.state} (${id})`);
          }
        }
      }
    },
    onSetRaceEnded(
      state,
      action: PayloadAction<{
        id: string;
        state: RaceStatus;
        raceDuration: number;
        riderSummary: RiderSummary[];
      }>,
    ) {
      const { id, riderSummary, raceDuration } = action.payload;
      if (state.activeSpace && state.activeSpace.id === id) {
        state.activeSpace.details.state = action.payload.state;
        state.activeSpace.raceEndRiderSummary = riderSummary;
        state.activeSpace.raceDuration = raceDuration;
      }
    },
    onSetRiderFinished(
      state,
      action: PayloadAction<{ raceId: string; riderId: string; finishTime: number }>,
    ) {
      const { raceId, riderId, finishTime } = action.payload;
      if (state.activeSpace && state.activeSpace.id === raceId) {
        state.activeSpace.ridersFinishedSummary[riderId] = {
          id: riderId,
          finishTime,
        };
      }
    },
    onSetClientOffset(state, action: PayloadAction<{ clockOffset: number; latency: number }>) {
      state.clockOffset = action.payload.clockOffset;
      state.latency = action.payload.latency;
    },
    onSetSendAudio(state, action: PayloadAction<boolean>) {
      state.sendAudio = action.payload;
    },
    onSetSendVideo(state, action: PayloadAction<boolean>) {
      state.sendVideo = action.payload;
    },
    onSetAlwaysJoinActiveSpaceAv(state, action: PayloadAction<{ alwaysJoin: boolean }>) {
      state.alwaysJoinActiveSpaceAv = action.payload.alwaysJoin;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isLogoutAction, (state, action) => {
      // reset any personalised state on logout
      state.activeSpace = null;
      state.activeGroupSpace = null;
      state.activeSpaceAv = null;
      state.spaceParticipants = {};
    });
  },
});

export const spacesReducer = spacesSlice.reducer;
export const spacesActions = spacesSlice.actions;

export const spacesSelectors = {
  getAvailableSpaces: (state: RootState) => state.spaces.availableSpaces,
  getActiveSpace: (state: RootState) => state.spaces.activeSpace,
  getActiveGroupSpace: (state: RootState) => state.spaces.activeGroupSpace,
  getActiveSpaceAv: (state: RootState) => state.spaces.activeSpaceAv,
  getClockOffset: (state: RootState) => state.spaces.clockOffset,
  getSpaceParticipants: (state: RootState) => state.spaces.spaceParticipants,
  getRaceStartTime: (state: RootState) => state.spaces.activeSpace?.raceStartTime,
  getRaceParticipantsState: (state: RootState) => state.spaces.activeSpace?.participantsState,
  getRaceParticipantsMeasures: (state: RootState) => state.spaces.activeSpace?.participantMeasures,
  getSendAudio: (state: RootState) => state.spaces.sendAudio,
  getSendVideo: (state: RootState) => state.spaces.sendVideo,
  getAlwaysJoinActiveSpaceAv: (state: RootState) => state.spaces.alwaysJoinActiveSpaceAv,
};
