import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useWsApi, useWsConnectedStatus, useWsResponseStatus } from '~/module/api';
import { useIsUserActive } from '~/hooks';
import { appActions, spacesActions } from '~/store/slices';

import { useListeners } from './useListeners';

export function useSubscriptionManager() {
  const dispatch = useDispatch();
  const ws = useWsApi();
  const { startMessageListeners, stopMessageListeners } = useListeners();

  const isSocketConnected = useWsConnectedStatus(ws);
  const isUserActive = useIsUserActive();

  useEffect(() => {
    // only auto reconnect if user is active
    if (isUserActive) {
      ws.startReconnectionListener();
    } else {
      ws.stopReconnectionListener();
    }

    if (isUserActive && isSocketConnected) {
      ws.startPing();
      startMessageListeners(ws);
      // ws.requestCurrentState();
    } else {
      ws.stopPing();
      stopMessageListeners();
    }
  }, [ws, isSocketConnected, isUserActive, startMessageListeners, stopMessageListeners]);

  useEffect(() => {
    const unsubscribe = ws.registerClockListener(({ clockOffset, latency }) => {
      dispatch(spacesActions.onSetClientOffset({ clockOffset, latency }));
    });
    return () => unsubscribe();
  }, [ws, dispatch]);

  useEffect(() => {
    dispatch(appActions.onSetSocketConnected({ connected: isSocketConnected }));
  }, [isSocketConnected, dispatch]);

  useEffect(() => {
    return () => {
      ws.teardown();
    };
  }, [ws]);

  return null;
}

export const SubscriptionManager = () => {
  useSubscriptionManager();
  return null;
};
