import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Daily, { DailyCall, DailyCallOptions } from '@daily-co/daily-js';
import {
  DailyEvent,
  DailyEventObjectCameraError,
  DailyEventObjectFatalError,
} from '@daily-co/daily-js';

import { reportError } from '~/module/logging';
import { spacesSelectors } from '~/store/slices';

/* We decide what UI to show to users based on the state of the app, which is dependent on the state of the call object. */
const STATE_IDLE = 0;
const STATE_JOINED = 1;
const STATE_ERROR = -1;

export const useSimpleAvCall = (roomId: string, token: string, enabled: boolean) => {
  const startAudioOff = !useSelector(spacesSelectors.getSendAudio);
  const startVideoOff = !useSelector(spacesSelectors.getSendVideo);

  const [appState, setAppState] = useState(STATE_IDLE);

  const optsRef = useRef<Partial<DailyCallOptions>>({ startAudioOff, startVideoOff });
  optsRef.current = { startAudioOff, startVideoOff };

  const [callObject, setCallObject] = useState<DailyCall | null>(null);

  const destroyCallObject = useCallback(async (callObject: DailyCall | null) => {
    if (callObject) {
      if (callObject.meetingState() === 'joined-meeting') {
        await callObject.leave();
      }
      callObject.destroy();
    }
  }, []);

  const createCallObject = useCallback(
    async ({ roomId }: { roomId: string }, existingObject: DailyCall | null) => {
      await destroyCallObject(existingObject);
      const opts: DailyCallOptions = {
        url: `https://critz.daily.co/${roomId}`,
        subscribeToTracksAutomatically: false,
        startAudioOff: optsRef.current.startAudioOff,
        startVideoOff: optsRef.current.startVideoOff,
      };
      const callObj = Daily.createCallObject(opts);
      return callObj;
    },
    [destroyCallObject],
  );

  useEffect(() => {
    if (enabled && roomId) {
      createCallObject({ roomId }, callObjectRef.current).then((callObj) => {
        setCallObject(callObj);
      });
      return () => {
        setCallObject((state) => {
          destroyCallObject(state);
          return null;
        });
      };
    }
  }, [enabled, roomId, createCallObject, destroyCallObject]);

  const callObjectRef = useRef(callObject);
  callObjectRef.current = callObject;

  useEffect(() => {
    if (!callObject) {
      setAppState(STATE_IDLE);
      return;
    }

    const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      if (!callObject) return;
      switch (callObject.meetingState()) {
        case 'new':
        case 'joining-meeting':
        case 'loaded':
        case 'loading':
          setAppState(STATE_IDLE);
          break;
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        case 'left-meeting':
          setAppState(STATE_IDLE);
          break;

        default:
          console.log('unknown meeting state', callObject.meetingState());
          break;
      }
    }

    function handleError(e?: DailyEventObjectFatalError | DailyEventObjectCameraError) {
      console.error(e);
      reportError(`[daily:${e?.error?.type || 'unknown'}] ${e?.errorMsg}`);
    }

    handleNewMeetingState();

    events.forEach((event) => callObject.on(event, handleNewMeetingState));
    callObject.on('error', handleError);
    callObject.on('camera-error', handleError);
    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
      callObject.off('error', handleError);
      callObject.off('camera-error', handleError);
    };
  }, [callObject]);

  const joinCall = useCallback(() => {
    if (token) {
      return callObjectRef.current?.join({
        token,
        receiveSettings: {
          base: { video: { layer: 1 } },
        },
      });
    }
  }, [token]);

  const leaveCall = useCallback(async () => {
    if (callObjectRef.current) {
      const state = callObjectRef.current.meetingState();
      if (state === 'joined-meeting') {
        await callObjectRef.current.leave();
      }
    }
  }, []);

  const canJoin = enabled && !!callObject;

  console.log(appState);

  return {
    canJoin,
    callObject,
    appState,
    joinCall,
    leaveCall,
  };
};
