import { PropsWithChildren, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DailyProvider } from '@daily-co/daily-react';

import { useAuth, useDevSwitches } from '~/store/hooks';
import { spacesSelectors, userSelectors } from '~/store/slices';

import { useSimpleAvCall } from '../hooks';
import { AvListener } from './AvListener';

export const AvContext = createContext({ appState: 0 });

export const AvProvider = ({ children }: PropsWithChildren<{}>) => {
  const { enableAv } = useDevSwitches();
  const { authenticated } = useAuth();

  const userAvDetails = useSelector(userSelectors.getUserAvDetails);
  const activeSpaceAv = useSelector(spacesSelectors.getActiveSpaceAv);

  const isAvCallEnabled = enableAv && !!activeSpaceAv;

  const { callObject, appState, canJoin, joinCall, leaveCall } = useSimpleAvCall(
    userAvDetails?.meetingRoomId ?? '',
    userAvDetails?.meetingToken ?? '',
    isAvCallEnabled && authenticated && !!userAvDetails,
  );

  useEffect(() => {
    if (canJoin && isAvCallEnabled) {
      joinCall();
      return () => {
        leaveCall();
      };
    }
  }, [canJoin, isAvCallEnabled, joinCall, leaveCall]);

  return (
    <AvContext.Provider value={{ appState }}>
      <DailyProvider callObject={callObject}>
        {children}
        {isAvCallEnabled && <AvListener appState={appState} />}
      </DailyProvider>
    </AvContext.Provider>
  );
};
