import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';

import {
  UpdateUserRequestData,
  UpdateUserResponseData,
  WhoAmIRequestData,
  WhoAmIResponseData,
} from '~/module/api/api.types';
import { User, userActions, userSelectors } from '~/store/slices';
import { transformUpdateUserToApi } from '~/subscriptions/transforms';

import { useWsSyncRequest } from './useWsSyncRequest';

export const useWsUser = () => {
  const dispatch = useDispatch();

  const user = useSelector(userSelectors.getUser);

  const { send: fetchUser } = useWsSyncRequest<WhoAmIRequestData, WhoAmIResponseData>({
    method: 'whoAmI',
  });

  const {
    isLoading: isUpdatingUser,
    error: updateUserError,
    send: updateUser,
  } = useWsSyncRequest<UpdateUserRequestData, UpdateUserResponseData>({ method: 'updateUser' });

  const { send: checkUniqueUsername } = useWsSyncRequest<
    UpdateUserRequestData,
    UpdateUserResponseData
  >({ method: 'checkUniqueUsername' });

  // user data is fetched via firebase listener
  // except for distance scale property, which must be mastered by server
  const onFetchUser = useCallback(async () => {
    const userResponse = await fetchUser();
    if (userResponse && userResponse.data && userResponse.data.valid) {
      dispatch(
        userActions.onSetUserDistanceScale({
          userDistanceScale: userResponse.data.User.distanceScale,
        }),
      );
      if (userResponse.data.AV) {
        dispatch(
          userActions.onSetAvDetails({
            meetingRoomId: userResponse.data.AV.meetingRoomId,
            meetingToken: userResponse.data.AV.meetingToken,
          }),
        );
      }
      if (
        userResponse.data.User.state.raceState?.spaceUUID &&
        userResponse.data.User.state.raceState.status !== 'finished'
      ) {
        dispatch(
          userActions.onSetCurrentActiveRace({
            id: userResponse.data.User.state.raceState.spaceUUID,
          }),
        );
      } else {
        dispatch(userActions.onSetCurrentActiveRace({ id: null }));
      }
    } else {
      throw new Error(
        `fetchUser - Invalid response [${_get(
          userResponse,
          'data.invalid_reason',
          'unknown err',
        )}]`,
      );
    }
  }, [fetchUser, dispatch]);

  const onUpdateUser = useCallback(
    async (update: Partial<User>) => {
      const response = await updateUser(transformUpdateUserToApi(update));
      if (response && response.data && response.data.valid) {
        onFetchUser();
      } else {
        throw new Error(
          `updateUser - Invalid response [${_get(response, 'data.invalid_reason', 'unknown err')}]`,
        );
      }
    },
    [updateUser, onFetchUser],
  );

  const onValidateUserName = useCallback(
    async (userName: string) => {
      const response = await checkUniqueUsername({ userName });
      if (response && response.data) {
        return response.data.valid;
      } else {
        throw new Error(
          `validateUserName - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown err',
          )}]`,
        );
      }
    },
    [checkUniqueUsername],
  );

  return {
    user,
    isUpdatingUser,
    updateUserError,
    onUpdateUser,
    onFetchUser,
    onValidateUserName,
  };
};
