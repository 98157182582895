import { useEffect, useRef } from 'react';
import { useMediaTrack } from '@daily-co/daily-react';

export const AudioTrack = ({ id }: { id: string }) => {
  const audioTrack = useMediaTrack(id, 'audio');
  const audioElement = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioTrack?.state === 'playable' && audioTrack.persistentTrack) {
      if (audioElement?.current) {
        audioElement.current.srcObject =
          audioTrack && new MediaStream([audioTrack.persistentTrack]);
      }
    }
  }, [audioTrack]);

  if (!audioTrack) return null;

  return <audio autoPlay playsInline ref={audioElement} />;
};
