import { useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAuth } from '~/store/hooks';
import { Box, Modal, Spinner } from '~/components/common';
import { useBooleanToggle } from '~/hooks';

import { SceneProps } from './Navigation.types';
import { absoluteUrls, dynamicUrls } from './Navigation.paths';
import { SceneContainer } from './layout/Content';
import {
  NotFoundScene,
  HomeScene,
  SpaceScene,
  UserProfileScene,
  SignInScene,
  ResetPasswordScene,
  SignUpScene,
  CreateSpaceScene,
  ConnectDevicesScene,
  RaceHistoryScene,
  UserPowerScene,
  FindRaceScene,
  StravaSettingsScene,
  StravaAuthScene,
  RaceHistoryItemScene,
  ObserveSpaceScene,
  TournamentsScene,
  TournamentScene,
  LandingPageScene,
} from './scenes';
import { CommsChannelsManager } from '~/features/AudioVideo/components/CommsChannelsManager';

export const SceneManager = () => {
  const { authStateKnown, authenticated } = useAuth();

  // const {
  //   value: isProfileModalOpen,
  //   onSetTrue: onOpenProfileModal,
  //   onSetFalse: onCloseProfileModal,
  // } = useBooleanToggle(false);

  const {
    value: isDevicesModalOpen,
    onSetTrue: onOpenDevicesModal,
    onSetFalse: onCloseDevicesModal,
  } = useBooleanToggle(false);

  const {
    value: isCommsModalOpen,
    onSetTrue: onOpenCommsModal,
    onSetFalse: onCloseCommsModal,
  } = useBooleanToggle(false);

  const sceneProps: SceneProps = useMemo(() => {
    return {
      isAuthenticated: authenticated,
      // onOpenProfileModal,
      // onCloseProfileModal,
      onOpenDevicesModal,
      onCloseDevicesModal,
      onOpenCommsModal,
    };
  }, [
    authenticated,
    // onOpenProfileModal,
    // onCloseProfileModal,
    onOpenDevicesModal,
    onCloseDevicesModal,
    onOpenCommsModal,
  ]);

  if (!authStateKnown) {
    return (
      <Box justify="center" align="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  return (
    <BrowserRouter>
      <SceneContainer {...sceneProps}>
        <Routes>
          <Route
            path={absoluteUrls.resetPassword}
            element={<ResetPasswordScene {...sceneProps} />}
          />
          <Route path={absoluteUrls.stravaAuth} element={<StravaAuthScene {...sceneProps} />} />
          {!authenticated ? (
            <>
              <Route path={absoluteUrls.signUp} element={<SignUpScene {...sceneProps} />} />
              <Route path={absoluteUrls.signIn} element={<SignInScene {...sceneProps} />} />
              <Route path="*" element={<LandingPageScene {...sceneProps} />} />
            </>
          ) : (
            <>
              <Route path={absoluteUrls.home} element={<HomeScene {...sceneProps} />} />
              <Route path={absoluteUrls.findRace} element={<FindRaceScene {...sceneProps} />} />
              <Route path={dynamicUrls.space} element={<SpaceScene {...sceneProps} />} />
              <Route
                path={absoluteUrls.tournaments}
                element={<TournamentsScene {...sceneProps} />}
              />
              <Route path={dynamicUrls.tournament} element={<TournamentScene {...sceneProps} />} />
              <Route
                path={dynamicUrls.observeSpace}
                element={<ObserveSpaceScene {...sceneProps} />}
              />
              <Route
                path={absoluteUrls.createSpace}
                element={<CreateSpaceScene {...sceneProps} />}
              />
              <Route
                path={absoluteUrls.userProfile}
                element={<UserProfileScene {...sceneProps} />}
              />
              <Route
                path={absoluteUrls.userRaceHistory}
                element={<RaceHistoryScene {...sceneProps} />}
              />
              <Route
                path={dynamicUrls.userRaceHistoryItem}
                element={<RaceHistoryItemScene {...sceneProps} />}
              />
              <Route path={absoluteUrls.userPower} element={<UserPowerScene {...sceneProps} />} />
              <Route
                path={absoluteUrls.userStravaSettings}
                element={<StravaSettingsScene {...sceneProps} />}
              />

              <Route path={absoluteUrls.notFound} element={<NotFoundScene {...sceneProps} />} />

              <Route path="*" element={<Navigate to={absoluteUrls.notFound} />} />
            </>
          )}
        </Routes>

        {authenticated && (
          <>
            {/* <Modal isVisible={isProfileModalOpen} onRequestClose={onCloseProfileModal}>
              <UserProfileScene {...sceneProps} />
            </Modal> */}

            <Modal
              isVisible={isDevicesModalOpen}
              onRequestClose={onCloseDevicesModal}
              width="xlarge"
            >
              <ConnectDevicesScene {...sceneProps} />
            </Modal>

            <Modal isVisible={isCommsModalOpen} onRequestClose={onCloseCommsModal} width="xlarge">
              <CommsChannelsManager onClose={onCloseCommsModal} />
            </Modal>
          </>
        )}
      </SceneContainer>
    </BrowserRouter>
  );
};
