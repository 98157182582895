import { useCallback, useEffect, useState } from 'react';

import { WSService } from '../services';

export const useWsResponseStatus = (
  ws: Maybe<WSService>,
  opts: { timeSinceLastMessageThreshold: number },
) => {
  const [responsive, setIsResponsive] = useState(true);

  const checkResponseTime = useCallback(
    (lastResponseTime: number) => {
      const timeSinceLastPing = new Date().valueOf() - lastResponseTime;
      if (timeSinceLastPing > opts.timeSinceLastMessageThreshold) {
        setIsResponsive(false);
      } else {
        setIsResponsive(true);
      }
    },
    [opts],
  );

  useEffect(() => {
    if (ws) {
      let intervalId: NodeJS.Timeout;
      intervalId = setInterval(() => {
        checkResponseTime(ws.lastMessageReceived);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [ws, checkResponseTime]);

  return responsive;
};
