import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RaceSpace, spacesActions, spacesSelectors } from '../slices';

export function useAvailableSpaces() {
  const dispatch = useDispatch();
  const availableSpaces = useSelector(spacesSelectors.getAvailableSpaces);

  const onAvailableSpacesUpdated = useCallback(
    (spaces: RaceSpace[]) => {
      dispatch(spacesActions.onAvailableSpacesUpdated({ spaces }));
    },
    [dispatch],
  );

  return {
    availableSpaces,
    onAvailableSpacesUpdated,
  };
}
